import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const ParkCreditNoteDocument = gql`
  mutation ParkCreditNote($input: ParkCreditNoteMutationInput!) {
    parkCreditNote(input: $input) {
      creditNote {
        id
        workflowState
      }
    }
  }
` as unknown as DocumentNode<Types.ParkCreditNoteMutation, Types.ParkCreditNoteMutationVariables>
