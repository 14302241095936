import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './inventory_graphql'

export const GetLegacyUnpaginatedStockCountsDocument = gql`
  query GetLegacyUnpaginatedStockCounts($organisationId: Int!, $stocktakeId: Int, $stockLocationId: Int) {
    legalHolder(organisationId: $organisationId) {
      id
      stockLocation(id: $stockLocationId) {
        id
        name
        stockTake(id: $stocktakeId) {
          periodEndingOn
          id
          createdAt
          closedAt
          blind
          closable
          stockTakeStatus
          stockCounts {
            unpaginated {
              expectedQuantity
              id
              openingQuantity
              quantity
              variance
              countBy
              productCode
              position
              product {
                brand
                id
                itemDescription
                itemMeasure
                itemSellQuantity
                itemPackName
                itemSize
                itemId
                concatenatedSellUnit
                itemSellPackName
                categoryId
                barcodes {
                  edges {
                    node {
                      id
                      body
                      isDefault
                      holder {
                        id
                        purchaser {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.GetLegacyUnpaginatedStockCountsQuery,
  Types.GetLegacyUnpaginatedStockCountsQueryVariables
>
