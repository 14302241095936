import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const FinanceFlagsFragmentDoc = gql`
  fragment FinanceFlags on FinanceFlagSettings {
    invoiceUnmatchedBlockage
    invoiceTotalBlockage
    invoiceSupplierNumberBlockage
    invoiceReferenceBlockage
    invoiceNotReceivedBlockage
    invoiceLineUnitPriceBlockage
    invoiceLineUnmatchedBlockage
    invoiceLineReceivedQuantityBlockage
    invoiceLineOriginalLineTotalBlockage
    invoiceLineOrderedQuantityBlockage
    invoiceFlaggedLineBlockage
    invoiceDepartmentBlockage
    invoiceCurrencyMatchBlockage
    invoiceApprovalBlockage
    invoiceAccountBlockage
    id
  }
` as unknown as DocumentNode<Types.FinanceFlagsFragment, unknown>
