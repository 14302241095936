import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const DeleteRequisitionLineDocument = gql`
  mutation DeleteRequisitionLine($input: DeleteRequisitionLineMutationInput!) {
    deleteRequisitionLine(input: $input) {
      requisition {
        id
        totalValue
        totalTaxValue
        totalValueExcludingTax
        lines {
          id
          uuid
          product {
            id
          }
          cataloguedProductId
          quantity
        }
        requisitionLinesBySuppliers {
          supplier {
            id
            name
          }
          total
          totalTax
          lines {
            id
            uuid
            quantity
            cataloguedProductId
            unitPrice
            product {
              id
            }
            orderInstruction
            department {
              id
              name
            }
            account {
              id
              accountName
              code
            }
            tax
            total
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.DeleteRequisitionLineMutation, Types.DeleteRequisitionLineMutationVariables>
