import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetPurchaseOrderDetailsDocument = gql`
  query GetPurchaseOrderDetails($purchaseOrderId: Int) {
    currentPurchaser {
      id
      purchaseOrder(id: $purchaseOrderId) {
        id
        consolidated
        purchaseOrderNumber
        requisition {
          id
          reference
          requisitionNumber
        }
        statusEnum
        receivedStatus
        invoicedStatus
        taxCodeInfo
        taxCodeId
        currency
        supplier {
          id
          name
          telephone
        }
        purchaser {
          id
          name
        }
        organisationUnit {
          id
          name
        }
        department {
          id
          name
        }
        account {
          id
          code
          accountName
        }
        sentDate
        sendToSupplier
        expectedDeliveryDate
        deliveryAddress {
          id
          locationName
          line1
          line2
          city
          stateProvince
          country
        }
        invoices {
          edges {
            node {
              id
              invoiceNumber
            }
          }
        }
        receivingDocuments {
          edges {
            node {
              id
              receivingDocumentNumber
              status
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetPurchaseOrderDetailsQuery, Types.GetPurchaseOrderDetailsQueryVariables>
