import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CancelPurchaseOrderDocument = gql`
  mutation CancelPurchaseOrder($input: CancelPurchaseOrderMutationInput!) {
    cancelPurchaseOrder(input: $input) {
      purchaseOrder {
        id
        statusEnum
      }
    }
  }
` as unknown as DocumentNode<Types.CancelPurchaseOrderMutation, Types.CancelPurchaseOrderMutationVariables>
