import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CreateTransferDocument = gql`
  mutation CreateTransfer($input: CreateTransferInput!, $showGhostLines: Boolean) {
    createTransfer(input: $input) {
      transfer {
        id
        transferItems(showGhostLines: $showGhostLines) {
          unpaginated {
            id
            stockItem {
              id
            }
            unitValue
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.CreateTransferMutation, Types.CreateTransferMutationVariables>
