import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetFinanceExportFormatDocument = gql`
  query GetFinanceExportFormat($financeExportFormatId: Int!) {
    financeExportFormat(id: $financeExportFormatId) {
      createdAt
      exportClassName
      family
      gqlQuery
      id
      name
      outputFormat
      useAccountingPeriod
    }
  }
` as unknown as DocumentNode<Types.GetFinanceExportFormatQuery, Types.GetFinanceExportFormatQueryVariables>
