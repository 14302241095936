import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const UpdateLegacyReceivingDocumentDocument = gql`
  mutation UpdateLegacyReceivingDocument($input: UpdateReceivingDocumentMutationInput!) {
    updateReceivingDocument(input: $input) {
      receivingDocument {
        id
        physicalDate
        destination {
          id
          name
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.UpdateLegacyReceivingDocumentMutation,
  Types.UpdateLegacyReceivingDocumentMutationVariables
>
