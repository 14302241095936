import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateUserOrganisationDocument = gql`
  mutation CreateUserOrganisation($input: CreateUserOrganisationMutationInput!) {
    createUserOrganisation(input: $input) {
      userOrganisation {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.CreateUserOrganisationMutation, Types.CreateUserOrganisationMutationVariables>
