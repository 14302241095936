import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CreateReportExecutionDocument = gql`
  mutation CreateReportExecution($input: CreateReportExecutionInput!) {
    createReportExecution(input: $input) {
      reportExecution {
        completedAt
        createdAt
        dateRange
        downloadUrl
        email
        id
        organisationHierarchy
        outputFormat
        reportClassName
        reportNumber
        selectedColumns
        status
        user {
          id
          fullName
        }
      }
    }
  }
` as unknown as DocumentNode<Types.CreateReportExecutionMutation, Types.CreateReportExecutionMutationVariables>
