import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateCatalogImportDocument = gql`
  mutation CreateCatalogImport($input: CreatePricedCatalogueImportMutationInput!) {
    createPricedCatalogueImport(input: $input) {
      pricedCatalogueImport {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.CreateCatalogImportMutation, Types.CreateCatalogImportMutationVariables>
