import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const ResendCreditNoteDocument = gql`
  mutation ResendCreditNote($input: ResendCreditNoteMutationInput!) {
    resendCreditNote(input: $input) {
      creditNote {
        id
        workflowState
      }
    }
  }
` as unknown as DocumentNode<Types.ResendCreditNoteMutation, Types.ResendCreditNoteMutationVariables>
