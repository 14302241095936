import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetSupplierRelationshipDetailsDocument = gql`
  query GetSupplierRelationshipDetails($id: Int) {
    currentPurchaser {
      id
      supplierRelationship(id: $id) {
        id
        supplierLocationCode
        supplierNumber
        isEnabled
        isEnabledBySupplier
        preferredSupplier
        paperlessInvoicingEmailAddress
        owner {
          id
          name
        }
        supplier {
          id
          name
          isVerified
          billingAddressLine1
          billingAddressLine2
          billingAddressCity
          billingAddressStateProvince
          billingAddressPostalCode
          postalAddressLine1
          postalAddressLine2
          postalAddressCity
          postalAddressStateProvince
          postalAddressPostalCode
          telephone
          email
          businessNumber
          owner {
            id
            name
          }
        }
        department {
          id
          name
        }
        account {
          id
          accountName
        }
        defaultCategory {
          id
          name
          parent {
            id
            name
          }
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.GetSupplierRelationshipDetailsQuery,
  Types.GetSupplierRelationshipDetailsQueryVariables
>
