import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const UpdateRecipeLineItemDocument = gql`
  mutation UpdateRecipeLineItem($input: UpdateRecipeLineItemInput!) {
    updateRecipeLineItem(input: $input) {
      recipeLineItem {
        id
        measure
        portion
        cost
        stockedCostPrice
        recipe {
          id
          stockedCostPrice
          liveCostPrice
        }
      }
    }
  }
` as unknown as DocumentNode<Types.UpdateRecipeLineItemMutation, Types.UpdateRecipeLineItemMutationVariables>
