import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { ActivityFragmentDoc } from './Activity.generated'
import * as Types from './purchasing_graphql'

export const GetPricedCatalogueActivitiesDocument = gql`
  query GetPricedCatalogueActivities($pricedCatalogueId: Int) {
    currentPurchaser {
      id
      pricedCatalogue(id: $pricedCatalogueId) {
        id
        activities {
          edges {
            node {
              ...Activity
            }
          }
        }
      }
    }
  }
  ${ActivityFragmentDoc}
` as unknown as DocumentNode<Types.GetPricedCatalogueActivitiesQuery, Types.GetPricedCatalogueActivitiesQueryVariables>
