import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const UpdateRequisitionDocument = gql`
  mutation UpdateRequisition($input: UpdateRequisitionMutationInput!) {
    updateRequisition(input: $input) {
      requisition {
        id
        reference
        sendToSupplier
        expectedDeliveryDate
        nextApprovingAgentsNames
        advanced
        currency
        taxCodeId
        taxCodeInfo
        approvalWorkflow {
          id
          title
        }
        deliveryAddress {
          id
          locationName
          line1
          line2
          city
          stateProvince
          country
        }
        department {
          id
          name
        }
        organisationUnit {
          id
          name
        }
        account {
          id
          accountName
        }
      }
    }
  }
` as unknown as DocumentNode<Types.UpdateRequisitionMutation, Types.UpdateRequisitionMutationVariables>
