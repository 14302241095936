import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetAllTransfersDocument = gql`
  query GetAllTransfers(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchText: String
    $sort: [RansackSortType!]
    $status: TransferStatusEnum
    $fromDate: ISO8601Date
    $destinationId: Int
    $toDate: ISO8601Date
    $originId: Int
    $transferTypes: [TransferTypeEnum!]
  ) {
    transfers(
      after: $after
      before: $before
      first: $first
      last: $last
      searchText: $searchText
      sort: $sort
      status: $status
      fromDate: $fromDate
      destinationId: $destinationId
      toDate: $toDate
      originId: $originId
      transferTypes: $transferTypes
    ) {
      edges {
        node {
          id
          transferNumber
          reference
          type
          origin {
            id
            name
          }
          destination {
            id
            name
          }
          status
          createdAt
          updatedAt
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAllTransfersQuery, Types.GetAllTransfersQueryVariables>
