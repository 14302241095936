import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CompleteTransferDocument = gql`
  mutation CompleteTransfer($input: CompleteTransferInput!) {
    completeTransfer(input: $input) {
      transfer {
        ... on Transfer {
          id
          status
        }
      }
    }
  }
` as unknown as DocumentNode<Types.CompleteTransferMutation, Types.CompleteTransferMutationVariables>
