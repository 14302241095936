import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BarcodeFragmentDoc } from './Barcode.generated'
import { PageInfoFragmentDoc } from './PageInfo.generated'
import { ProductFragmentDoc } from './Product.generated'
import { SupplierDetailByLineFragmentDoc } from './SupplierDetailByLine.generated'
import * as Types from './purchasing_graphql'

export const GetPurchaseOrderReceivingNoteDocument = gql`
  query GetPurchaseOrderReceivingNote(
    $receivingDocumentId: Int
    $filter: RansackFilterType
    $purchaseOrderId: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    currentPurchaser {
      id
      purchaseOrder(id: $purchaseOrderId) {
        id
        totalValueExcludingTax
        purchaseOrderNumber
        supplier {
          ...SupplierDetailByLine
        }
        receivingDocument(id: $receivingDocumentId) {
          id
          status
          receivingDocumentNumber
          receivingTotalValue
          receivedDate
          physicalDate
          owner {
            id
            name
          }
          organisationUnit {
            id
            name
          }
          receiver {
            id
            fullName
          }
          transfer {
            id
            transferNumber
          }
          receivingDocumentLineItems(filter: $filter, after: $after, before: $before, first: $first, last: $last) {
            edges {
              node {
                id
                quantity
                receivedQuantity
                comment
                unitPrice
                overrideUnitValue
                expDate
                temperature
                vehicleTemperature
                totalValue
                purchaseOrderLineItem {
                  id
                  quantity
                  image
                  unitPrice
                  supplierProductCode
                  invoiceLineItems {
                    edges {
                      node {
                        id
                        quantity
                        unitPrice
                        taxPercentage
                        createdAt
                        invoice {
                          id
                          invoiceNumber
                        }
                      }
                    }
                  }
                }
                receivingDocument {
                  id
                  status
                }
                product {
                  ...Product
                  barcodes {
                    edges {
                      node {
                        ...Barcode
                      }
                    }
                  }
                }
              }
            }
            pageInfo {
              ...PageInfo
            }
          }
          destination {
            id
            name
          }
        }
      }
    }
  }
  ${SupplierDetailByLineFragmentDoc}
  ${ProductFragmentDoc}
  ${BarcodeFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<
  Types.GetPurchaseOrderReceivingNoteQuery,
  Types.GetPurchaseOrderReceivingNoteQueryVariables
>
