import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const CreateOrganisationImportDocument = gql`
  mutation CreateOrganisationImport($input: CreateOrganisationImportInput!) {
    createOrganisationImport(input: $input) {
      organisationImport {
        id
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.CreateOrganisationImportMutation, Types.CreateOrganisationImportMutationVariables>
