import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CreateRecipeLineItemDocument = gql`
  mutation CreateRecipeLineItem($input: CreateRecipeLineItemInput!) {
    createRecipeLineItem(input: $input) {
      recipeLineItem {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.CreateRecipeLineItemMutation, Types.CreateRecipeLineItemMutationVariables>
