import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetUserOrganisationsDocument = gql`
  query GetUserOrganisations($userId: Int!, $after: String, $before: String, $first: Int, $last: Int) {
    user(id: $userId) {
      organisations(after: $after, before: $before, first: $first, last: $last) {
        edges {
          node {
            id
            name
            isASupplier
            isAPurchaser
            legalEntity
            isVerified
            parent {
              id
              name
            }
            createdAt
            owner {
              id
              name
            }
            children {
              totalCount
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetUserOrganisationsQuery, Types.GetUserOrganisationsQueryVariables>
