import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetTradePipesDocument = gql`
  query GetTradePipes(
    $after: String
    $before: String
    $filter: RansackFilterType
    $first: Int
    $last: Int
    $sort: [RansackSortType!]
  ) {
    pipes(after: $after, before: $before, filter: $filter, first: $first, last: $last, sort: $sort) {
      edges {
        node {
          id
          type
          externalUrl
          exportFormat
          attachmentFormat
          createdAt
          updatedAt
          isDefault
          owner {
            id
            name
          }
          tradeRelationships {
            totalCount
            unpaginated {
              id
              isEnabled
              isEnabledBySupplier
              status
              purchaser {
                id
                name
                accountsPayableSystem {
                  id
                  name
                }
              }
              supplier {
                id
                name
              }
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetTradePipesQuery, Types.GetTradePipesQueryVariables>
