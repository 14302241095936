import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateCommentDocument = gql`
  mutation CreateComment($input: CreateCommentMutationInput!) {
    createComment(input: $input) {
      comment {
        id
        body
      }
    }
  }
` as unknown as DocumentNode<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>
