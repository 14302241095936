import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './inventory_graphql'

export const UpdateStockCountDocument = gql`
  mutation UpdateStockCount($input: UpdateStockCountMutationInput!) {
    updateStockCount(input: $input) {
      stockCount {
        id
        quantity
        openingQuantity
        expectedQuantity
        variance
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.UpdateStockCountMutation, Types.UpdateStockCountMutationVariables>
