import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const CreateElectronicDocumentDestinationDocument = gql`
  mutation CreateElectronicDocumentDestination($input: CreateElectronicDocumentDestinationInput!) {
    createElectronicDocumentDestination(input: $input) {
      errors {
        ...BaseError
      }
      electronicDocumentDestination {
        id
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<
  Types.CreateElectronicDocumentDestinationMutation,
  Types.CreateElectronicDocumentDestinationMutationVariables
>
