import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateCatalogExportDocument = gql`
  mutation CreateCatalogExport($input: CreatePricedCatalogueExportMutationInput!) {
    createPricedCatalogueExport(input: $input) {
      pricedCatalogueExport {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.CreateCatalogExportMutation, Types.CreateCatalogExportMutationVariables>
