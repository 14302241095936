import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetOrganisationImportsDocument = gql`
  query GetOrganisationImports(
    $filters: OrganisationImportsQueryFiltersInput
    $first: Int
    $after: String
    $before: String
    $last: Int
    $sorts: [OrganisationImportsQuerySortsInput!]
  ) {
    organisationImports(filters: $filters, first: $first, after: $after, before: $before, last: $last, sorts: $sorts) {
      edges {
        node {
          id
          state
          createdAt
          importFileLink
          importFileName
          importErrorFileLink
          importInformation
          user {
            id
            fullName
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetOrganisationImportsQuery, Types.GetOrganisationImportsQueryVariables>
