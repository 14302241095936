import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './inventory_graphql'

export const DeleteStockCountDocument = gql`
  mutation DeleteStockCount($input: DeleteStockCountMutationInput!) {
    deleteStockCount(input: $input) {
      clientMutationId
      errors {
        ...BaseError
      }
      success
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.DeleteStockCountMutation, Types.DeleteStockCountMutationVariables>
