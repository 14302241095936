import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const DeleteInvoiceDocument = gql`
  mutation DeleteInvoice($input: DeleteInvoiceMutationInput!) {
    deleteInvoice(input: $input) {
      success
    }
  }
` as unknown as DocumentNode<Types.DeleteInvoiceMutation, Types.DeleteInvoiceMutationVariables>
