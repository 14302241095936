import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CreateStocktakeDocument = gql`
  mutation CreateStocktake($input: CreateStockTakeInput!) {
    createStockTake(input: $input) {
      stockTake {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.CreateStocktakeMutation, Types.CreateStocktakeMutationVariables>
