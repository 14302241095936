import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetMenuDocument = gql`
  query GetMenu($menuId: Int!) {
    menu(id: $menuId) {
      id
      name
      createdAt
      updatedAt
      owner {
        id
        name
      }
      recipes {
        totalCount
      }
    }
  }
` as unknown as DocumentNode<Types.GetMenuQuery, Types.GetMenuQueryVariables>
