import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { AttachmentUpdatorFragmentDoc } from './AttachmentUpdator.generated'
import * as Types from './purchasing_graphql'

export const GetInvoiceAttachmentsDocument = gql`
  query GetInvoiceAttachments($invoiceId: Int, $sort: [RansackSortType!]) {
    currentPurchaser {
      id
      invoice(id: $invoiceId) {
        id
        invoiceNumber
        filesCount
        invoiceAttachments(sort: $sort) {
          edges {
            node {
              id
              fileFileName
              caption
              createdAt
              updator {
                ...AttachmentUpdator
              }
            }
          }
        }
      }
    }
  }
  ${AttachmentUpdatorFragmentDoc}
` as unknown as DocumentNode<Types.GetInvoiceAttachmentsQuery, Types.GetInvoiceAttachmentsQueryVariables>
