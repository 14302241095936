import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasing_graphql'

export const ApproveRequisitionDocument = gql`
  mutation ApproveRequisition($input: ApproveRequisitionMutationInput!) {
    approveRequisition(input: $input) {
      errors {
        ...BaseError
      }
      requisition {
        id
        purchaserOrganisationId
        awaitingMyApproval
        status
        nextApprovingAgentsNames
        nextApprovingUsers {
          id
          fullName
        }
        purchaseOrders {
          edges {
            node {
              id
              sentDate
              purchaseOrderNumber
              supplier {
                id
                name
              }
              totalValue
              statusEnum
            }
          }
        }
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.ApproveRequisitionMutation, Types.ApproveRequisitionMutationVariables>
