import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const DeletePricedCataloguedProductDocument = gql`
  mutation DeletePricedCataloguedProduct($input: DeletePricedCataloguedProductMutationInput!) {
    deletePricedCataloguedProduct(input: $input) {
      success
    }
  }
` as unknown as DocumentNode<
  Types.DeletePricedCataloguedProductMutation,
  Types.DeletePricedCataloguedProductMutationVariables
>
