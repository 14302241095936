import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasing_graphql'

export const GetCatalogImportsDocument = gql`
  query GetCatalogImports(
    $pricedCatalogueId: Int
    $sort: [RansackSortType!]
    $last: Int
    $first: Int
    $after: String
    $before: String
  ) {
    currentPurchaser {
      id
      pricedCatalogue(id: $pricedCatalogueId) {
        id
        catalogueImports(sort: $sort, last: $last, first: $first, after: $after, before: $before) {
          edges {
            node {
              id
              importFileLink
              importFileName
              importErrorFileLink
              importErrorFileName
              importInformation
              scheduledAt
              completedAt
              state
              createdAt
              user {
                id
                fullName
              }
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetCatalogImportsQuery, Types.GetCatalogImportsQueryVariables>
