import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const UpdateElectronicDocumentDestinationDocument = gql`
  mutation UpdateElectronicDocumentDestination($input: UpdateElectronicDocumentDestinationInput!) {
    updateElectronicDocumentDestination(input: $input) {
      errors {
        ...BaseError
      }
      electronicDocumentDestination {
        id
        name
        externalUrl
        identity
        sharedSecret
        oauthUrl
        financeExportDefault
        pipe
        contentType
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<
  Types.UpdateElectronicDocumentDestinationMutation,
  Types.UpdateElectronicDocumentDestinationMutationVariables
>
