import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import { StockItemFragmentDoc } from './StockItem.generated'
import * as Types from './purchasePlus_graphql'

export const GetTransferItemsDocument = gql`
  query GetTransferItems(
    $transferId: Int!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sort: [RansackSortType!]
    $searchText: String
    $stockItemId: Int
    $purchaserProductCode: String
    $categoryIds: [Int!]
    $barcode: String
    $showGhostLines: Boolean
  ) {
    transfer(id: $transferId) {
      id
      transferItems(
        after: $after
        before: $before
        first: $first
        last: $last
        sort: $sort
        searchText: $searchText
        stockItemId: $stockItemId
        purchaserProductCode: $purchaserProductCode
        categoryIds: $categoryIds
        barcode: $barcode
        showGhostLines: $showGhostLines
      ) {
        edges {
          node {
            unitValue
            totalCost
            quantity
            value
            id
            origin {
              id
              unitValue
              balanceQuantity
            }
            stockItem {
              ...StockItem
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${StockItemFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetTransferItemsQuery, Types.GetTransferItemsQueryVariables>
