import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const ParkInvoiceDocument = gql`
  mutation ParkInvoice($input: ParkInvoiceMutationInput!) {
    parkInvoice(input: $input) {
      invoice {
        id
        status
      }
    }
  }
` as unknown as DocumentNode<Types.ParkInvoiceMutation, Types.ParkInvoiceMutationVariables>
