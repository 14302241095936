import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetPurchaseOrderPipesDocument = gql`
  query GetPurchaseOrderPipes($purchaseOrderId: Int!) {
    purchaseOrder(id: $purchaseOrderId) {
      id
      supplier {
        id
        pipes {
          unpaginated {
            id
            externalUrl
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetPurchaseOrderPipesQuery, Types.GetPurchaseOrderPipesQueryVariables>
