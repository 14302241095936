import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetAccountsPayableSystemTaxCodesDocument = gql`
  query GetAccountsPayableSystemTaxCodes(
    $after: String
    $before: String
    $filters: TaxCodesQueryFiltersInput
    $first: Int
    $last: Int
    $sorts: [TaxCodesQuerySortsInput!]
    $accountsPayableSystemId: Int
  ) {
    accountsPayableSystem(id: $accountsPayableSystemId) {
      id
      taxCodes(after: $after, before: $before, filters: $filters, first: $first, last: $last, sorts: $sorts) {
        edges {
          node {
            id
            code
            description
            percentage
            isDefault
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<
  Types.GetAccountsPayableSystemTaxCodesQuery,
  Types.GetAccountsPayableSystemTaxCodesQueryVariables
>
