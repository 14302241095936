import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CreateSubstituteProductDocument = gql`
  mutation CreateSubstituteProduct($input: CreateSubstituteProductInput!) {
    createSubstituteProduct(input: $input) {
      substituteProduct {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.CreateSubstituteProductMutation, Types.CreateSubstituteProductMutationVariables>
