import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const SpendDataFragmentDoc = gql`
  fragment SpendData on SpendData {
    invoicesProcessed
    invoicesTotalValue
    poSent
    poTotalValue
    topTenProducts {
      label
      value
    }
    topTenSuppliers {
      label
      value
    }
  }
` as unknown as DocumentNode<Types.SpendDataFragment, unknown>
export const GetSpendDataDocument = gql`
  query GetSpendData {
    spendDataCurrentMonth: spendData(spendDataMonth: current) {
      ...SpendData
    }
    spendDataPreviousMonth: spendData(spendDataMonth: previous) {
      ...SpendData
    }
  }
  ${SpendDataFragmentDoc}
` as unknown as DocumentNode<Types.GetSpendDataQuery, Types.GetSpendDataQueryVariables>
