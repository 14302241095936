import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { FlaggingMessageFragmentDoc } from './FlaggingMessage.generated'
import { PageInfoFragmentDoc } from './PageInfo.generated'
import { ProductFragmentDoc } from './Product.generated'
import * as Types from './purchasing_graphql'

export const MatchPurchaseOrderInvoiceDocument = gql`
  mutation MatchPurchaseOrderInvoice(
    $input: MatchPurchaseOrderInvoiceMutationInput!
    $sortLines: [RansackSortType!]
    $first: Int
  ) {
    matchPurchaseOrderInvoice(input: $input) {
      invoice {
        id
        status
        flaggingMessages {
          ...FlaggingMessage
        }
        invoiceLineItems(sort: $sortLines, first: $first) {
          edges {
            node {
              id
              quantity
              unitPrice
              taxPercentage
              orderInstruction
              lineTotal
              lineTax
              originalLineTotal
              receivedStatusEnum
              orderedQuantity
              totalReceivedQuantity
              totalInvoicedQuantity
              productMatchedInvoicedQuantity
              productMatchedReceivedQuantity
              productMatchedOrderedQuantity
              productMatchedOrderedPrice
              purchaseOrderLineItemId
              subLines {
                edges {
                  node {
                    id
                    department {
                      id
                      name
                    }
                    account {
                      id
                      accountName
                      code
                    }
                    unitPrice
                    tax
                    taxRate
                    quantity
                    totalValue
                    lineType
                    department {
                      id
                      name
                    }
                    account {
                      id
                      accountName
                    }
                    description
                    reference
                    customJson
                  }
                }
              }
              purchaseOrderLineItem {
                id
                quantity
                unitPrice
                taxPercentage
                procurementProductId
                purchaseOrder {
                  id
                  currency
                }
                product {
                  id
                  category {
                    id
                    name
                    parent {
                      id
                      name
                    }
                  }
                  brand
                  image
                  itemDescription
                  concatenatedSellUnit
                  itemPackName
                  itemMeasure
                  itemSellPackName
                  itemSize
                }
              }
              originalProductId
              invoiceId
              product: originalProduct {
                ...Product
              }
              flaggingMessages {
                ...FlaggingMessage
              }
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
        totalValue
        totalTaxValue
        totalValueExTax
        deliveryChargeTax
        deliveryChargeExTax
        lineTotal
        lineTaxTotal
        adjustmentExTax
        adjustmentTax
        outOfBalance
        purchaseOrderId
        isOperationallyApproved
        isFinanciallyApproved
        operationallyApprovable
        financiallyApprovable
        department {
          id
          name
        }
        account {
          id
          code
        }
        financialApprovers {
          edges {
            node {
              id
              createdAt
              user {
                id
                fullName
              }
            }
          }
        }
        operationalApprovers {
          edges {
            node {
              id
              createdAt
              user {
                id
                fullName
              }
            }
          }
        }
        receivingDocuments {
          edges {
            node {
              id
              receivingDocumentNumber
              status
            }
          }
        }
        purchaseOrder {
          id
          purchaseOrderNumber
          currency
          requisition {
            id
            reference
            requisitionNumber
          }
          supplier {
            id
            name
          }
          department {
            id
            name
          }
          account {
            id
            code
          }
          statusEnum
          sentDate
          receivedStatus
          invoicedStatus
          expectedDeliveryDate
          totalValue
          invoices {
            edges {
              node {
                id
                invoiceNumber
                totalValue
              }
            }
          }
        }
      }
    }
  }
  ${FlaggingMessageFragmentDoc}
  ${ProductFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.MatchPurchaseOrderInvoiceMutation, Types.MatchPurchaseOrderInvoiceMutationVariables>
