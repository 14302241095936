import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const AttachmentUpdatorFragmentDoc = gql`
  fragment AttachmentUpdator on User {
    id
    fullName
  }
` as unknown as DocumentNode<Types.AttachmentUpdatorFragment, unknown>
