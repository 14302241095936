import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { RecipeFragmentDoc } from './Recipe.generated'
import * as Types from './purchasePlus_graphql'

export const GetRecipeDocument = gql`
  query GetRecipe($recipeId: Int!) {
    recipe(id: $recipeId) {
      ...Recipe
    }
  }
  ${RecipeFragmentDoc}
` as unknown as DocumentNode<Types.GetRecipeQuery, Types.GetRecipeQueryVariables>
