import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetPurchaseOrderInvoiceCreatorDocument = gql`
  query GetPurchaseOrderInvoiceCreator($purchaseOrderId: Int!) {
    purchaseOrder(id: $purchaseOrderId) {
      id
      department {
        id
        name
      }
      account {
        id
        name
        accountName: codedName
      }
      requisition {
        id
        reference
      }
      expectedDeliveryDate
      purchaseOrderNumber
      id
      purchaser {
        id
        name
      }
      supplier {
        id
        name
      }
      totalValueExcludingTax
      totalTaxValue
      currency
      lines {
        unpaginated {
          unitPrice
          taxPercentage
          quantity
          product {
            id
            brand
            itemDescription
            itemMeasure
            itemPackName
            itemSellPackName
            itemSellQuantity
            itemSize
          }
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.GetPurchaseOrderInvoiceCreatorQuery,
  Types.GetPurchaseOrderInvoiceCreatorQueryVariables
>
