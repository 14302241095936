import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetRequisitionStatusActivitiesDocument = gql`
  query GetRequisitionStatusActivities($requisitionId: Int) {
    currentPurchaser {
      id
      requisition(id: $requisitionId) {
        id
        status
        requisitionNumber
        awaitingMyApproval
        advanced
        requestor {
          id
          fullName
          createdAt
        }
        approvalWorkflow {
          id
          title
        }
        nextApprovalUsers {
          id
          fullName
        }
        submitActivity {
          id
          createdAt
          user {
            id
            fullName
          }
        }
        approveActivity {
          id
          createdAt
          user {
            id
            fullName
          }
        }
        cancelActivity {
          id
          createdAt
          user {
            id
            fullName
          }
        }
        declineActivity {
          id
          createdAt
          user {
            id
            fullName
          }
        }
        declineComment {
          id
          body
        }
        nextApprovingUsers {
          id
          fullName
        }
        purchaseOrders {
          edges {
            node {
              id
              purchaseOrderNumber
              statusEnum
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.GetRequisitionStatusActivitiesQuery,
  Types.GetRequisitionStatusActivitiesQueryVariables
>
