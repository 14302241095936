import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetCreditNoteDetailsDocument = gql`
  query GetCreditNoteDetails($creditNoteId: Int) {
    currentPurchaser {
      id
      creditNote(id: $creditNoteId) {
        id
        creditNoteNumber
        creditNoteDate
        reference
        workflowState
        currency
        customJson
        invoice {
          id
          invoiceNumber
        }
        purchaser {
          id
          name
        }
        organisationUnit {
          id
          name
        }
        department {
          id
          name
        }
        account {
          id
          code
          accountName
        }
        supplier {
          id
          name
        }
        deliveryChargeExTax
        deliveryChargeTax
        totalValue
        totalTaxValue
        taxCodeInfo
      }
    }
  }
` as unknown as DocumentNode<Types.GetCreditNoteDetailsQuery, Types.GetCreditNoteDetailsQueryVariables>
