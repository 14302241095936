import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetExternalCataloguesDocument = gql`
  query GetExternalCatalogues($first: Int, $after: String, $search: String) {
    externalCatalogues(after: $after, first: $first, search: $search) {
      edges {
        node {
          id
          name
          originId
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
` as unknown as DocumentNode<Types.GetExternalCataloguesQuery, Types.GetExternalCataloguesQueryVariables>
