import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasing_graphql'

export const GetRequisitionsDraftDocument = gql`
  query GetRequisitionsDraft(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: RansackFilterType
    $sort: [RansackSortType!]
  ) {
    currentPurchaser {
      id
      requisitions(first: $first, last: $last, before: $before, after: $after, filter: $filter, sort: $sort) {
        edges {
          node {
            id
            reference
            sourceApplication
            editableForCurrentUser
            requisitionNumber
            totalValue
            totalValueExcludingTax
            totalTaxValue
            expectedDeliveryDate
            advanced
            originName
            originType
            status
            currency
            owner {
              name
            }
            account {
              id
              accountName
            }
            department {
              id
              name
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetRequisitionsDraftQuery, Types.GetRequisitionsDraftQueryVariables>
