import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const UpdateReceivingDocumentDocument = gql`
  mutation UpdateReceivingDocument($input: UpdateReceivingDocumentInput!) {
    updateReceivingDocument(input: $input) {
      receivingDocument {
        id
        physicalDate
        destination {
          id
          name
        }
      }
    }
  }
` as unknown as DocumentNode<Types.UpdateReceivingDocumentMutation, Types.UpdateReceivingDocumentMutationVariables>
