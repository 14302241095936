import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const ReceiveLegacyReceivingDocumentDocument = gql`
  mutation ReceiveLegacyReceivingDocument($input: ReceiveReceivingDocumentMutationInput!) {
    receiveReceivingDocument(input: $input) {
      receivingDocument {
        id
        purchaseOrder {
          id
          purchaseOrderNumber
          lines {
            nodes {
              id
              receivedStatus
            }
          }
        }
        receivingDocumentLineItems {
          edges {
            node {
              id
              quantity
              receivedQuantity
              purchaseOrderLineItem {
                id
                quantity
                receivedQuantity
              }
            }
          }
        }
        status
      }
    }
  }
` as unknown as DocumentNode<
  Types.ReceiveLegacyReceivingDocumentMutation,
  Types.ReceiveLegacyReceivingDocumentMutationVariables
>
