import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const DeleteSubstituteProductDocument = gql`
  mutation DeleteSubstituteProduct($input: DeleteSubstituteProductInput!) {
    deleteSubstituteProduct(input: $input) {
      success
    }
  }
` as unknown as DocumentNode<Types.DeleteSubstituteProductMutation, Types.DeleteSubstituteProductMutationVariables>
