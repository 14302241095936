import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const UpdateAccountsPayableSystemDocument = gql`
  mutation UpdateAccountsPayableSystem($input: UpdateAccountsPayableSystemInput!) {
    updateAccountsPayableSystem(input: $input) {
      accountsPayableSystem {
        id
        name
        oauthUrl
        sharedSecret
        hasSharedSecret
        identity
        externalUrl
        headerJsonSchema
        lineJsonSchema
        accountingPeriodStartMonth
        currency {
          id
          code
        }
        exportFormat {
          id
          name
        }
        financeExportFormat {
          id
          name
          useAccountingPeriod
          financeExportVariants {
            edges {
              node {
                id
                name
              }
            }
          }
        }
        financeExportVariant {
          id
          name
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.UpdateAccountsPayableSystemMutation,
  Types.UpdateAccountsPayableSystemMutationVariables
>
