import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './inventory_graphql'

export const GetLegacyStocktakeDocument = gql`
  query GetLegacyStocktake(
    $after: String
    $before: String
    $filter: RansackFilterType
    $first: Int
    $last: Int
    $sort: [RansackSortType!]
    $organisationId: Int!
    $stocktakeId: Int
    $stockLocationId: Int
  ) {
    legalHolder(organisationId: $organisationId) {
      id
      stockLocation(id: $stockLocationId) {
        id
        name
        stockTake(id: $stocktakeId) {
          periodEndingOn
          id
          createdAt
          closedAt
          blind
          closable
          stockTakeStatus
          stockCounts(after: $after, before: $before, filter: $filter, first: $first, last: $last, sort: $sort) {
            edges {
              node {
                expectedQuantity
                id
                openingQuantity
                quantity
                variance
                countBy
                productCode
                position
                product {
                  brand
                  id
                  itemDescription
                  itemMeasure
                  itemSellQuantity
                  itemPackName
                  itemSize
                  itemId
                  concatenatedSellUnit
                  itemSellPackName
                  categoryId
                  barcodes {
                    edges {
                      node {
                        id
                        body
                        isDefault
                        holder {
                          id
                          purchaser {
                            id
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            pageInfo {
              ...PageInfo
            }
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetLegacyStocktakeQuery, Types.GetLegacyStocktakeQueryVariables>
