import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const UpdateTradePipeDocument = gql`
  mutation UpdateTradePipe($input: UpdateTradePipeInput!) {
    updateTradePipe(input: $input) {
      tradePipe {
        id
        identity
        isDefault
        oauthUrl
        extraHeaders
        externalUrl
        exportFormat
        description
        attachmentFormat
        sharedSecret
        type
        owner {
          id
          name
        }
      }
    }
  }
` as unknown as DocumentNode<Types.UpdateTradePipeMutation, Types.UpdateTradePipeMutationVariables>
