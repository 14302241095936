import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const UpdateSupplierRelationshipDocument = gql`
  mutation UpdateSupplierRelationship($input: UpdateSupplierRelationshipMutationInput!) {
    updateSupplierRelationship(input: $input) {
      supplierRelationship {
        id
        supplierNumber
        supplierLocationCode
        paymentTerms
        punchoutEnabled
        punchoutUrl
        punchoutUsername
        punchoutPassword
        preferredSupplier
        contactEmail
        contactFirstName
        isEnabled
        isEnabledBySupplier
        contactLastName
        department {
          id
          name
        }
        account {
          id
          accountName
        }
        defaultCategory {
          id
          name
          parent {
            id
            name
          }
          familyCategoryId
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.UpdateSupplierRelationshipMutation,
  Types.UpdateSupplierRelationshipMutationVariables
>
