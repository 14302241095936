import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetReportExecutionsDocument = gql`
  query GetReportExecutions(
    $reportId: Int!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $reportNumber: String
  ) {
    report(id: $reportId) {
      id
      executions(after: $after, before: $before, first: $first, last: $last, reportNumber: $reportNumber) {
        edges {
          node {
            completedAt
            createdAt
            dateRange
            downloadUrl
            email
            id
            organisationHierarchy
            outputFormat
            reportClassName
            reportNumber
            selectedColumns
            status
            user {
              id
              fullName
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetReportExecutionsQuery, Types.GetReportExecutionsQueryVariables>
