import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const UpdateRequisitionLineDocument = gql`
  mutation UpdateRequisitionLine($input: UpdateRequisitionLineMutationInput!) {
    updateRequisitionLine(input: $input) {
      requisition {
        id
        totalValue
        totalTaxValue
        totalValueExcludingTax
        lines {
          id
          uuid
          product {
            id
          }
          quantity
          cataloguedProductId
        }
        requisitionLinesBySuppliers {
          supplier {
            id
            name
          }
          total
          subTotalExTax
          totalTax
          lines {
            id
            uuid
            quantity
            cataloguedProductId
            unitPrice
            product {
              id
            }
            orderInstruction
            taxPercentage
            department {
              id
              name
            }
            account {
              id
              accountName
              code
            }
            tax
            total
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.UpdateRequisitionLineMutation, Types.UpdateRequisitionLineMutationVariables>
