import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const UpdateTransferDocument = gql`
  mutation UpdateTransfer($input: UpdateTransferInput!) {
    updateTransfer(input: $input) {
      transfer {
        id
        reference
      }
    }
  }
` as unknown as DocumentNode<Types.UpdateTransferMutation, Types.UpdateTransferMutationVariables>
