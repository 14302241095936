import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { AttachmentUpdatorFragmentDoc } from './AttachmentUpdator.generated'
import * as Types from './purchasing_graphql'

export const GetRequisitionAttachmentsDocument = gql`
  query GetRequisitionAttachments($requisitionId: Int, $sort: [RansackSortType!]) {
    currentPurchaser {
      id
      requisition(id: $requisitionId) {
        id
        requisitionNumber
        filesCount
        requisitionAttachments(sort: $sort) {
          edges {
            node {
              id
              fileFileName
              caption
              createdAt
              updator {
                ...AttachmentUpdator
              }
            }
          }
        }
      }
    }
  }
  ${AttachmentUpdatorFragmentDoc}
` as unknown as DocumentNode<Types.GetRequisitionAttachmentsQuery, Types.GetRequisitionAttachmentsQueryVariables>
