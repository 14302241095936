import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const AddStockItemToStockLocationDocument = gql`
  mutation AddStockItemToStockLocation($input: AddStockItemToStockLocationInput!) {
    addStockItemToStockLocation(input: $input) {
      stockLevel {
        id
      }
    }
  }
` as unknown as DocumentNode<
  Types.AddStockItemToStockLocationMutation,
  Types.AddStockItemToStockLocationMutationVariables
>
