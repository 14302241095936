import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetInvoiceDetailsDocument = gql`
  query GetInvoiceDetails($invoiceId: Int) {
    currentPurchaser {
      id
      invoice(id: $invoiceId) {
        id
        invoiceNumber
        invoiceDate
        reference
        status
        attachmentUrl
        inception
        hasLinkedLines
        purchaseOrderId
        taxCodeInfo
        taxCodeId
        currency
        customJson
        purchaser {
          id
          name
        }
        organisationUnit {
          id
          name
        }
        department {
          id
          name
        }
        account {
          id
          code
          accountName
        }
        supplier {
          id
          name
        }
        purchaseOrder {
          id
          purchaseOrderNumber
        }
        receivingDocuments {
          edges {
            node {
              id
              receivingDocumentNumber
            }
          }
        }
        creditNotes {
          edges {
            node {
              id
              creditNoteNumber
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetInvoiceDetailsQuery, Types.GetInvoiceDetailsQueryVariables>
