import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetDeliveryAddressesDocument = gql`
  query GetDeliveryAddresses($filter: RansackFilterType, $sort: [RansackSortType!], $first: Int, $after: String) {
    currentPurchaser {
      id
      defaultDeliveryAddress {
        id
        locationName
        line1
        line2
        city
        stateProvince
        country
      }
      deliveryAddresses(filter: $filter, sort: $sort, first: $first, after: $after) {
        edges {
          node {
            id
            locationName
            line1
            line2
            city
            stateProvince
            country
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetDeliveryAddressesQuery, Types.GetDeliveryAddressesQueryVariables>
