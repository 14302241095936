import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const ArchiveStockItemDocument = gql`
  mutation ArchiveStockItem($input: ArchiveStockItemInput!) {
    archiveStockItem(input: $input) {
      stockItem {
        id
        archivable
      }
    }
  }
` as unknown as DocumentNode<Types.ArchiveStockItemMutation, Types.ArchiveStockItemMutationVariables>
