import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const UpdateReceivingDocumentLineItemDocument = gql`
  mutation UpdateReceivingDocumentLineItem($input: UpdateReceivingDocumentLineItemInput!) {
    updateReceivingDocumentLineItem(input: $input) {
      receivingDocumentLineItem {
        id
        expiryDate
        comment
        overrideUnitValue
        vehicleTemperature
        unitValue
        unitPrice
        temperature
        quantity
        receivedQuantity
        transferReceivingUnitValue
        transferReceivingQuantity
        transferReceivingProduct {
          id
          image
          category {
            id
            name
            parent {
              id
              name
            }
          }
          brand
          itemDescription
          concatenatedDescription
          concatenatedSellUnit
          itemPackName
          itemMeasure
          itemSellPackName
          itemSize
          productCode
          master
          owner {
            id
            name
          }
          canonicalProductId
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.UpdateReceivingDocumentLineItemMutation,
  Types.UpdateReceivingDocumentLineItemMutationVariables
>
