import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { CommentFragmentDoc } from './Comment.generated'
import { DeliveryFragmentDoc } from './Delivery.generated'
import { PageInfoFragmentDoc } from './PageInfo.generated'
import { ProductFragmentDoc } from './Product.generated'
import { SupplierDetailByLineFragmentDoc } from './SupplierDetailByLine.generated'
import * as Types from './purchasing_graphql'

export const GetPurchaseOrderDocument = gql`
  query GetPurchaseOrder(
    $purchaseOrderId: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: RansackFilterType
    $sortDeliveries: [RansackSortType!]
    $sortComments: [RansackSortType!]
  ) {
    currentPurchaser {
      id
      purchaseOrder(id: $purchaseOrderId) {
        id
        consolidated
        purchaseOrderNumber
        expectedDeliveryDate
        sendToSupplier
        currency
        purchaser {
          id
          name
        }
        organisationUnit {
          id
          name
        }
        department {
          id
          name
        }
        account {
          id
          accountName
          code
        }
        requisition {
          id
          reference
          requisitionNumber
        }
        invoices {
          edges {
            node {
              id
              invoiceNumber
            }
          }
        }
        receivingDocuments {
          edges {
            node {
              id
              receivingDocumentNumber
              status
            }
          }
        }
        deliveries(sort: $sortDeliveries) {
          edges {
            node {
              ...Delivery
            }
          }
        }
        deliveryChargeExTax
        deliveryChargeTax
        sentDate
        totalValueExcludingTax
        totalTaxValue
        totalValue
        statusEnum
        receivedStatus
        taxCodeInfo
        taxCodeId
        deliveryAddress {
          id
          locationName
          line1
          line2
          city
          stateProvince
          country
        }
        invoicedStatus
        commentsCount
        supplier {
          ...SupplierDetailByLine
        }
        comments(sort: $sortComments) {
          edges {
            node {
              ...Comment
            }
          }
        }
        purchaseOrderLineItems(after: $after, before: $before, first: $first, last: $last, filter: $filter) {
          edges {
            node {
              id
              quantity
              receivedQuantity
              unitPrice
              taxAmount
              lineTax
              orderInstruction
              productCode
              lineTotal
              image
              supplierProductCode
              longDescription
              description
              taxPercentage
              receivedStatus
              invoicedStatus
              invoiceLineItems {
                edges {
                  node {
                    id
                    quantity
                  }
                }
              }
              subLines {
                edges {
                  node {
                    id
                    department {
                      id
                      name
                    }
                    account {
                      id
                      accountName
                      code
                    }
                    unitPrice
                    taxPercentage
                    quantity
                    orderInstruction
                    lineTotal
                    lineTax
                  }
                }
              }
              product {
                ...Product
              }
              department {
                id
                name
              }
              account {
                id
                accountName
                code
              }
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${DeliveryFragmentDoc}
  ${SupplierDetailByLineFragmentDoc}
  ${CommentFragmentDoc}
  ${ProductFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetPurchaseOrderQuery, Types.GetPurchaseOrderQueryVariables>
