import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetMenuRecipesDocument = gql`
  query GetMenuRecipes(
    $menuId: Int!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sorts: [RecipesQuerySortsInput!]
    $filters: RecipesQueryFiltersInput
  ) {
    menu(id: $menuId) {
      id
      recipes(after: $after, before: $before, first: $first, last: $last, sorts: $sorts, filters: $filters) {
        edges {
          node {
            id
            image
            name
            code
            yieldQuantity
            costPercentage
            costPricePerUnit
            retailPricePerUnit
            measure
            size
            owner {
              id
              name
            }
            createdAt
            menus {
              edges {
                node {
                  id
                }
              }
              totalCount
            }
            stockItem {
              id
              itemSize
              itemPackName
              itemMeasure
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetMenuRecipesQuery, Types.GetMenuRecipesQueryVariables>
