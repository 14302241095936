import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetAccountsPayableSystemsDocument = gql`
  query GetAccountsPayableSystems($after: String, $before: String, $first: Int, $last: Int, $searchText: String) {
    accountsPayableSystems(after: $after, before: $before, first: $first, last: $last, searchText: $searchText) {
      edges {
        node {
          id
          name
          financeExportFormat {
            id
            name
          }
          financeExportVariant {
            id
            name
          }
          electronicDocumentDestinations {
            totalCount
          }
          currency {
            id
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAccountsPayableSystemsQuery, Types.GetAccountsPayableSystemsQueryVariables>
