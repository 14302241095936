import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { SupplierDetailByLineFragmentDoc } from './SupplierDetailByLine.generated'
import * as Types from './purchasing_graphql'

export const AdvancedRequisitionBodyFragmentDoc = gql`
  fragment AdvancedRequisitionBody on Requisition {
    id
    totalValue
    totalTaxValue
    totalValueExcludingTax
    lines {
      id
      uuid
      product {
        id
      }
      quantity
      cataloguedProductId
      orderInstruction
      total
      supplier {
        ...SupplierDetailByLine
      }
      department {
        id
        name
      }
      account {
        id
        code
        accountName
      }
    }
  }
  ${SupplierDetailByLineFragmentDoc}
` as unknown as DocumentNode<Types.AdvancedRequisitionBodyFragment, unknown>
