import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetBuyListDetailsDocument = gql`
  query GetBuyListDetails($catalogueId: Int) {
    currentPurchaser {
      id
      catalogue(id: $catalogueId) {
        id
        title
        createdAt
        owner {
          id
          name
        }
        updatedAt
        customSorted
        user {
          id
          fullName
        }
        chargingDefinitions {
          edges {
            node {
              id
              department {
                id
                name
              }
              account {
                id
                accountName
              }
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetBuyListDetailsQuery, Types.GetBuyListDetailsQueryVariables>
