import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import { ProductFragmentDoc } from './Product.generated'
import { SupplierDetailByLineFragmentDoc } from './SupplierDetailByLine.generated'
import * as Types from './purchasing_graphql'

export const GetAdvancedPossibleRequisitionLinesDocument = gql`
  query GetAdvancedPossibleRequisitionLines(
    $requisitionId: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
    $term: String
    $bestBuy: Boolean
    $supplierIds: [Int!]
    $categoryIds: [Int!]
    $productId: Int
    $supplierProductCode: String
    $purchaserProductCode: String
    $excludeZeroQuantity: Boolean
    $nextGenQuotes: Boolean
  ) {
    currentPurchaser {
      id
      requisition(id: $requisitionId) {
        id
        requisitionNumber
        reference
        totalValue
        totalTaxValue
        commentsCount
        awaitingMyApproval
        status
        originName
        originType
        expectedDeliveryDate
        sendToSupplier
        creator {
          id
          fullName
          createdAt
        }
        approvalWorkflow {
          id
          title
        }
        department {
          id
          name
        }
        account {
          id
          accountName
        }
        deliveryAddress {
          id
          locationName
          line1
          line2
          city
          stateProvince
          country
        }
        lines {
          id
          uuid
          product {
            id
          }
          cataloguedProductId
        }
        possibleRequisitionLines(
          after: $after
          before: $before
          first: $first
          last: $last
          bestBuy: $bestBuy
          searchText: $term
          supplierIds: $supplierIds
          categoryIds: $categoryIds
          productId: $productId
          supplierProductCode: $supplierProductCode
          purchaserProductCode: $purchaserProductCode
          excludeZeroQuantity: $excludeZeroQuantity
          nextGenQuotes: $nextGenQuotes
        ) {
          edges {
            node {
              id
              uuid
              cataloguedProductId
              image
              unitPrice
              taxPercentage
              unitTax
              quantity
              total
              livePremium(nextGenQuotes: $nextGenQuotes)
              orderInstruction
              preferredSupplier
              department {
                id
                name
              }
              account {
                id
                accountName
                code
              }
              product {
                ...Product
              }
              supplier {
                ...SupplierDetailByLine
                supplierRelationship {
                  id
                  preferredSupplier
                }
              }
              supplierProductCode
              purchaserProductCode
              availableQuotes(nextGenQuotes: $nextGenQuotes) {
                unitPrice
                taxPercentage
                supplierName
                supplierId
                cataloguedProductId
                preferredSupplier
                contracted
              }
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${ProductFragmentDoc}
  ${SupplierDetailByLineFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<
  Types.GetAdvancedPossibleRequisitionLinesQuery,
  Types.GetAdvancedPossibleRequisitionLinesQueryVariables
>
