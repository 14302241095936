import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasing_graphql'

export const CreateInvoiceLineItemDocument = gql`
  mutation CreateInvoiceLineItem($input: CreateInvoiceLineItemMutationInput!) {
    createInvoiceLineItem(input: $input) {
      invoiceLineItem {
        id
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.CreateInvoiceLineItemMutation, Types.CreateInvoiceLineItemMutationVariables>
