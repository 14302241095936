import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CreateOrUpdateAclUserPermissionsDocument = gql`
  mutation CreateOrUpdateAclUserPermissions($input: CreateOrUpdateAccessControlListInput!) {
    createOrUpdateAccessControlList(input: $input) {
      user {
        id
        isAdmin
        fullName
        accessControlList
      }
    }
  }
` as unknown as DocumentNode<
  Types.CreateOrUpdateAclUserPermissionsMutation,
  Types.CreateOrUpdateAclUserPermissionsMutationVariables
>
