import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { ActivityFragmentDoc } from './Activity.generated'
import * as Types from './purchasing_graphql'

export const GetPurchaseOrderActivitiesDocument = gql`
  query GetPurchaseOrderActivities($purchaseOrderId: Int) {
    currentPurchaser {
      id
      purchaseOrder(id: $purchaseOrderId) {
        id
        purchaseOrderNumber
        activities {
          edges {
            node {
              ...Activity
            }
          }
        }
      }
    }
  }
  ${ActivityFragmentDoc}
` as unknown as DocumentNode<Types.GetPurchaseOrderActivitiesQuery, Types.GetPurchaseOrderActivitiesQueryVariables>
