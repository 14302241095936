import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const MarkInvoiceExportReadyDocument = gql`
  mutation MarkInvoiceExportReady($input: MarkInvoiceExportReadyInput!) {
    markInvoiceExportReady(input: $input) {
      invoice {
        id
        status
      }
    }
  }
` as unknown as DocumentNode<Types.MarkInvoiceExportReadyMutation, Types.MarkInvoiceExportReadyMutationVariables>
