import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './inventory_graphql'

export const GetAllLegacyStocktakesDocument = gql`
  query GetAllLegacyStocktakes(
    $organisationId: Int!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: RansackFilterType
    $sort: [RansackSortType!]
  ) {
    legalHolder(organisationId: $organisationId) {
      id
      stockTakes(after: $after, before: $before, first: $first, last: $last, filter: $filter, sort: $sort) {
        edges {
          node {
            id
            periodEndingOn
            stockTakeStatus
            blind
            createdAt
            closedAt
            stockLocation {
              id
              name
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAllLegacyStocktakesQuery, Types.GetAllLegacyStocktakesQueryVariables>
