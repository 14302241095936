import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './inventory_graphql'

export const UpdateStockTakeDocument = gql`
  mutation UpdateStockTake($input: UpdateStockTakeMutationInput!) {
    updateStockTake(input: $input) {
      stockTake {
        id
        blind
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.UpdateStockTakeMutation, Types.UpdateStockTakeMutationVariables>
