import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetFinanceExportFormatsDocument = gql`
  query GetFinanceExportFormats(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchText: String
    $sort: String
  ) {
    financeExportFormats(
      after: $after
      before: $before
      first: $first
      last: $last
      searchText: $searchText
      sort: $sort
    ) {
      totalCount
      edges {
        node {
          exportClassName
          id
          name
          outputFormat
          financeExportVariants {
            totalCount
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetFinanceExportFormatsQuery, Types.GetFinanceExportFormatsQueryVariables>
