import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const DeleteReportExecutionDocument = gql`
  mutation DeleteReportExecution($input: DeleteReportExecutionInput!) {
    deleteReportExecution(input: $input) {
      success
    }
  }
` as unknown as DocumentNode<Types.DeleteReportExecutionMutation, Types.DeleteReportExecutionMutationVariables>
