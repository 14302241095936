import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetAllStockLocationsDocument = gql`
  query GetAllStockLocations(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sorts: [LocationsQuerySortsInput!]
    $filters: LocationsQueryFiltersInput
  ) {
    stockLocations(after: $after, before: $before, first: $first, last: $last, sorts: $sorts, filters: $filters) {
      edges {
        node {
          name
          id
          balance
          type
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAllStockLocationsQuery, Types.GetAllStockLocationsQueryVariables>
