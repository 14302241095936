import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const ReopenInvoiceDocument = gql`
  mutation ReopenInvoice($input: ReopenInvoiceMutationInput!) {
    reopenInvoice(input: $input) {
      invoice {
        id
        status
      }
    }
  }
` as unknown as DocumentNode<Types.ReopenInvoiceMutation, Types.ReopenInvoiceMutationVariables>
