import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasing_graphql'

export const CreatePricedCataloguedProductDocument = gql`
  mutation CreatePricedCataloguedProduct($input: CreatePricedCataloguedProductMutationInput!) {
    createPricedCataloguedProduct(input: $input) {
      pricedCataloguedProduct {
        id
        catalogueId
        sellUnitPrice
        sellUnitTaxPercentage
        pricedCatalogue {
          id
          supplier {
            id
            name
          }
        }
        product {
          id
        }
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<
  Types.CreatePricedCataloguedProductMutation,
  Types.CreatePricedCataloguedProductMutationVariables
>
