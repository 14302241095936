import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import { ProductFragmentDoc } from './Product.generated'
import * as Types from './purchasePlus_graphql'

export const GetReceivingNoteLineItemsDocument = gql`
  query GetReceivingNoteLineItems(
    $receivingDocumentId: Int!
    $after: String
    $before: String
    $filters: ReceivingDocumentLineItemsQueryFiltersInput
    $first: Int
    $last: Int
    $sorts: [ReceivingDocumentLineItemsQuerySortsInput!]
  ) {
    receivingDocument(id: $receivingDocumentId) {
      id
      receivingDocumentLineItems(
        after: $after
        before: $before
        filters: $filters
        first: $first
        last: $last
        sorts: $sorts
      ) {
        edges {
          node {
            id
            comment
            expiryDate
            location {
              id
              name
            }
            orderInstruction
            overrideUnitValue
            producedDate
            product {
              ...Product
              itemSellQuantity
            }
            quantity
            receivedQuantity
            receivedToInventory
            temperature
            totalValue
            transferReceivingUnitValue
            transferReceivingQuantity
            purchaseOrderLineItem {
              id
              quantity
              image
              unitPrice
              supplierProductCode
              invoiceLineItems {
                edges {
                  node {
                    id
                    quantity
                    unitPrice
                    taxPercentage
                  }
                }
              }
            }
            transferReceivingProduct {
              id
              image
              category {
                id
                name
                parent {
                  id
                  name
                }
              }
              brand
              itemDescription
              concatenatedDescription
              concatenatedSellUnit
              itemPackName
              itemMeasure
              itemSellPackName
              itemSize
              productCode
              master
              owner {
                id
                name
              }
              canonicalProductId
            }
            unitPrice
            unitValue
            vehicleTemperature
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${ProductFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetReceivingNoteLineItemsQuery, Types.GetReceivingNoteLineItemsQueryVariables>
