import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './access_graphql'

export const DeleteUserOrganisationDocument = gql`
  mutation DeleteUserOrganisation($input: DeleteUserOrganisationMutationInput!) {
    deleteUserOrganisation(input: $input) {
      success
    }
  }
` as unknown as DocumentNode<Types.DeleteUserOrganisationMutation, Types.DeleteUserOrganisationMutationVariables>
