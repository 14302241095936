import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const DeleteInvoiceSubLineDocument = gql`
  mutation DeleteInvoiceSubLine($input: DeleteInvoiceSubLineMutationInput!) {
    deleteInvoiceSubLine(input: $input) {
      invoiceLine {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.DeleteInvoiceSubLineMutation, Types.DeleteInvoiceSubLineMutationVariables>
