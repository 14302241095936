import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetAllOrganisationsDocument = gql`
  query GetAllOrganisations($filter: RansackFilterType, $sort: [RansackSortType!], $first: Int, $after: String) {
    organisations(filter: $filter, sort: $sort, first: $first, after: $after) {
      edges {
        node {
          id
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
` as unknown as DocumentNode<Types.GetAllOrganisationsQuery, Types.GetAllOrganisationsQueryVariables>
