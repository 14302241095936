import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreatePricedCatalogDocument = gql`
  mutation CreatePricedCatalog($input: CreatePricedCatalogueMutationInput!) {
    createPricedCatalogue(input: $input) {
      pricedCatalogue {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.CreatePricedCatalogMutation, Types.CreatePricedCatalogMutationVariables>
