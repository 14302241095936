import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetAllUsersDocument = gql`
  query GetAllUsers(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $isDeleted: Boolean
    $usersId: Int
    $searchText: String
    $sort: String
  ) {
    users(
      after: $after
      before: $before
      first: $first
      last: $last
      isDeleted: $isDeleted
      id: $usersId
      searchText: $searchText
      sort: $sort
    ) {
      edges {
        node {
          id
          fullName
          email
          confirmedAt
          position
          currentSignInAt
          isAdmin
          status
          telephone
          defaultOrganisation {
            id
            name
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAllUsersQuery, Types.GetAllUsersQueryVariables>
