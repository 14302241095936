import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasing_graphql'

export const CreateOrganisationDocument = gql`
  mutation CreateOrganisation($input: CreateOrganisationMutationInput!) {
    createOrganisation(input: $input) {
      organisation {
        id
        isAPurchaser
        isASupplier
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.CreateOrganisationMutation, Types.CreateOrganisationMutationVariables>
