import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetStockItemBarcodesDocument = gql`
  query GetStockItemBarcodes($stockItemId: Int!, $sort: [RansackSortType!]) {
    stockItem(id: $stockItemId) {
      id
      barcodes(sort: $sort) {
        edges {
          node {
            id
            body
            isDefault
            organisation {
              id
              name
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetStockItemBarcodesQuery, Types.GetStockItemBarcodesQueryVariables>
