import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetMySuppliersDocument = gql`
  query GetMySuppliers(
    $after: String
    $before: String
    $enabled: Boolean
    $first: Int
    $isVerified: Boolean
    $last: Int
    $preferredSupplier: Boolean
    $searchText: String
    $sort: String
    $accountsPayableSystemId: Int
  ) {
    accountsPayableSystem(id: $accountsPayableSystemId) {
      id
      suppliers(
        after: $after
        before: $before
        enabled: $enabled
        first: $first
        isVerified: $isVerified
        last: $last
        preferredSupplier: $preferredSupplier
        searchText: $searchText
        sort: $sort
      ) {
        edges {
          node {
            id
            name
            isVerified
            businessNumber
            email
            telephone
            creditApplicationUrl
            supplierRelationship {
              id
              isEnabled
              preferredSupplier
              supplierNumber
              supplierLocationCode
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetMySuppliersQuery, Types.GetMySuppliersQueryVariables>
