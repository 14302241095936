import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateInvoiceAttachmentDocument = gql`
  mutation CreateInvoiceAttachment($input: CreateInvoiceAttachmentMutationInput!) {
    createInvoiceAttachment(input: $input) {
      invoiceAttachment {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.CreateInvoiceAttachmentMutation, Types.CreateInvoiceAttachmentMutationVariables>
