import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateLegacyReceivingDocumentDocument = gql`
  mutation CreateLegacyReceivingDocument($input: CreateReceivingDocumentMutationInput!) {
    createReceivingDocument(input: $input) {
      receivingDocument {
        id
        purchaseOrder {
          id
          purchaseOrderNumber
        }
        status
      }
    }
  }
` as unknown as DocumentNode<
  Types.CreateLegacyReceivingDocumentMutation,
  Types.CreateLegacyReceivingDocumentMutationVariables
>
