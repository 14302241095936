import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateBuyListImportDocument = gql`
  mutation CreateBuyListImport($input: CreateCatalogueImportMutationInput!) {
    createCatalogueImport(input: $input) {
      catalogueImport {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.CreateBuyListImportMutation, Types.CreateBuyListImportMutationVariables>
