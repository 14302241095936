import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { ProductFragmentDoc } from './Product.generated'
import * as Types from './purchasing_graphql'

export const GetLegacyProductDocument = gql`
  query GetLegacyProduct($productId: Int, $nextGenQuotes: Boolean) {
    currentLegalEntity {
      id
      ... on Supplier {
        availableProducts(productId: $productId) {
          edges {
            node {
              ...Product
              itemSellQuantity
              externalId
              stockItem
            }
          }
        }
      }
      ... on Purchaser {
        accountsPayableSystemId
        availableProducts(productId: $productId, nextGenQuotes: $nextGenQuotes) {
          edges {
            node {
              ...Product
              itemSellQuantity
              externalId
              stockItem
            }
          }
        }
      }
    }
  }
  ${ProductFragmentDoc}
` as unknown as DocumentNode<Types.GetLegacyProductQuery, Types.GetLegacyProductQueryVariables>
