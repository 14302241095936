import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { AttachmentUpdatorFragmentDoc } from './AttachmentUpdator.generated'
import * as Types from './purchasing_graphql'

export const GetCreditNoteAttachmentsDocument = gql`
  query GetCreditNoteAttachments($creditNoteId: Int, $sort: [RansackSortType!]) {
    currentPurchaser {
      id
      creditNote(id: $creditNoteId) {
        id
        creditNoteNumber
        filesCount
        creditNoteAttachments(sort: $sort) {
          edges {
            node {
              id
              fileFileName
              caption
              createdAt
              updator {
                ...AttachmentUpdator
              }
            }
          }
        }
      }
    }
  }
  ${AttachmentUpdatorFragmentDoc}
` as unknown as DocumentNode<Types.GetCreditNoteAttachmentsQuery, Types.GetCreditNoteAttachmentsQueryVariables>
