import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasing_graphql'

export const GetBuyListExportsDocument = gql`
  query GetBuyListExports(
    $catalogueId: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sort: [RansackSortType!]
  ) {
    currentPurchaser {
      id
      catalogue(id: $catalogueId) {
        id
        catalogueExports(after: $after, before: $before, first: $first, last: $last, sort: $sort) {
          edges {
            node {
              id
              exportFileLink
              fileName
              createdAt
              state
              creator {
                id
                fullName
              }
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetBuyListExportsQuery, Types.GetBuyListExportsQueryVariables>
