import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { StockItemFragmentDoc } from './StockItem.generated'
import * as Types from './purchasePlus_graphql'

export const UpdateStockItemDocument = gql`
  mutation UpdateStockItem($input: UpdateStockItemInput!) {
    updateStockItem(input: $input) {
      stockItem {
        ...StockItem
      }
    }
  }
  ${StockItemFragmentDoc}
` as unknown as DocumentNode<Types.UpdateStockItemMutation, Types.UpdateStockItemMutationVariables>
