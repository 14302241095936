import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CommissionInventoryDocument = gql`
  mutation CommissionInventory($input: CommissionInventoryInput!) {
    commissionInventory(input: $input) {
      inventoryHolder {
        id
        purchaser {
          id
          name
        }
      }
    }
  }
` as unknown as DocumentNode<Types.CommissionInventoryMutation, Types.CommissionInventoryMutationVariables>
