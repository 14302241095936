import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateBuyListDocument = gql`
  mutation CreateBuyList($input: CreateCatalogueMutationInput!) {
    createCatalogue(input: $input) {
      catalogue {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.CreateBuyListMutation, Types.CreateBuyListMutationVariables>
