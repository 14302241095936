import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetRequisitionDetailsDocument = gql`
  query GetRequisitionDetails($requisitionId: Int) {
    currentPurchaser {
      id
      requisition(id: $requisitionId) {
        id
        requisitionNumber
        originName
        originType
        reference
        status
        awaitingMyApproval
        currency
        creator {
          id
          fullName
        }
        approvalWorkflow {
          id
          title
        }
        department {
          id
          name
        }
        account {
          id
          accountName
        }
        purchaser {
          id
          name
        }
        organisationUnit {
          id
          name
        }
        expectedDeliveryDate
        deliveryAddress {
          id
          locationName
          line1
          line2
          city
          stateProvince
          country
        }
        sendToSupplier
        taxCodeId
        taxCodeInfo
        purchaseOrders {
          edges {
            node {
              id
              purchaseOrderNumber
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetRequisitionDetailsQuery, Types.GetRequisitionDetailsQueryVariables>
