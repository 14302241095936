import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateProductSupplierPreferenceDocument = gql`
  mutation CreateProductSupplierPreference($input: CreateProductSupplierPreferenceMutationInput!) {
    createProductSupplierPreference(input: $input) {
      productSupplierPreference {
        id
        productId
        supplierId
        enabled
        purchaserId
      }
    }
  }
` as unknown as DocumentNode<
  Types.CreateProductSupplierPreferenceMutation,
  Types.CreateProductSupplierPreferenceMutationVariables
>
