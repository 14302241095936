import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetPricedCatalogDetailsDocument = gql`
  query GetPricedCatalogDetails($pricedCatalogueId: Int) {
    currentPurchaser {
      id
      pricedCatalogue(id: $pricedCatalogueId) {
        id
        title
        updatedAt
        createdAt
        contracted
        supplier {
          id
          name
        }
        owner {
          id
          ... on Supplier {
            name
            isAPurchaser
            isASupplier
          }
          ... on Purchaser {
            name
            isAPurchaser
            isASupplier
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetPricedCatalogDetailsQuery, Types.GetPricedCatalogDetailsQueryVariables>
