import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import { FlaggingMessageFragmentDoc } from './FlaggingMessage.generated'
import * as Types from './purchasing_graphql'

export const UnlinkInvoiceLineItemDocument = gql`
  mutation UnlinkInvoiceLineItem($input: UnlinkInvoiceLineItemMutationInput!) {
    unlinkInvoiceLineItem(input: $input) {
      errors {
        ...BaseError
      }
      invoiceLineItem {
        id
        purchaseOrderLineItemId
        productMatchedInvoicedQuantity
        productMatchedReceivedQuantity
        totalReceivedQuantity
        orderedQuantity
        receivedStatusEnum
        purchaseOrderLineItem {
          id
        }
        flaggingMessages {
          ...FlaggingMessage
        }
        invoice {
          id
          hasLinkedLines
        }
      }
    }
  }
  ${BaseErrorFragmentDoc}
  ${FlaggingMessageFragmentDoc}
` as unknown as DocumentNode<Types.UnlinkInvoiceLineItemMutation, Types.UnlinkInvoiceLineItemMutationVariables>
