import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { StockItemFragmentDoc } from './StockItem.generated'
import * as Types from './purchasePlus_graphql'

export const GetStockItemDocument = gql`
  query GetStockItem($stockItemId: Int!) {
    stockItem(id: $stockItemId) {
      ...StockItem
    }
  }
  ${StockItemFragmentDoc}
` as unknown as DocumentNode<Types.GetStockItemQuery, Types.GetStockItemQueryVariables>
