import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const ClosePurchaseOrdersDocument = gql`
  mutation ClosePurchaseOrders($input: ClosePurchaseOrdersInput!) {
    closePurchaseOrders(input: $input) {
      errors {
        ...BaseError
      }
      failed {
        errors {
          ...BaseError
        }
        purchaseOrder {
          id
          purchaseOrderNumber
          status
        }
      }
      processed {
        id
        purchaseOrderNumber
        status
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.ClosePurchaseOrdersMutation, Types.ClosePurchaseOrdersMutationVariables>
