import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetAccountsPayableSystemPurchasersDocument = gql`
  query GetAccountsPayableSystemPurchasers(
    $accountsPayableSystemId: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filters: PurchasersQueryFiltersInput
  ) {
    accountsPayableSystem(id: $accountsPayableSystemId) {
      id
      purchasers(after: $after, before: $before, first: $first, last: $last, filters: $filters) {
        edges {
          node {
            id
            businessUnit
            departmentCode
            name
          }
        }
        totalCount
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<
  Types.GetAccountsPayableSystemPurchasersQuery,
  Types.GetAccountsPayableSystemPurchasersQueryVariables
>
