import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import { ProductFragmentDoc } from './Product.generated'
import * as Types from './purchasing_graphql'

export const GetCreditNoteLinesDocument = gql`
  query GetCreditNoteLines(
    $creditNoteId: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: RansackFilterType
  ) {
    currentPurchaser {
      id
      creditNote(id: $creditNoteId) {
        id
        creditNoteLines(after: $after, before: $before, first: $first, last: $last, filter: $filter) {
          edges {
            node {
              id
              quantity
              unitPrice
              taxPercentage
              lineTotal
              lineTax
              product {
                ...Product
              }
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${ProductFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetCreditNoteLinesQuery, Types.GetCreditNoteLinesQueryVariables>
