import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const DeleteWorkflowRankMembershipDocument = gql`
  mutation DeleteWorkflowRankMembership($input: DeleteWorkflowRankMembershipInput!) {
    deleteWorkflowRankMembership(input: $input) {
      success
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<
  Types.DeleteWorkflowRankMembershipMutation,
  Types.DeleteWorkflowRankMembershipMutationVariables
>
