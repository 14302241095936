import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { FeatureFlagsFragmentDoc } from './FeatureFlags.generated'
import { PageInfoFragmentDoc } from './PageInfo.generated'
import { ProductFragmentDoc } from './Product.generated'
import * as Types from './purchasing_graphql'

export const GetSupplierDocument = gql`
  query GetSupplier($supplierId: Int!, $searchText: String, $after: String, $first: Int, $before: String, $last: Int) {
    supplier(id: $supplierId) {
      id
      name
      billingAddressLine1
      billingAddressLine2
      billingAddressCity
      billingAddressStateProvince
      billingAddressPostalCode
      billingAddressCountry
      postalAddressLine1
      postalAddressLine2
      postalAddressCity
      postalAddressStateProvince
      postalAddressPostalCode
      postalAddressCountry
      telephone
      email
      businessNumber
      businessName
      businessUnit
      creditApplicationUrl
      departmentCode
      corporateCode
      companyBrand
      legalEntity
      createdAt
      deliveryChargeConfiguration {
        deliveryChargeExTax
        deliveryChargeTaxPercentage
        minimumOrderValueExTax
      }
      isASupplier
      supplierRelationship {
        id
        isEnabled
      }
      featureFlags {
        ...FeatureFlags
      }
      publicCataloguedProducts(searchText: $searchText, after: $after, first: $first, before: $before, last: $last) {
        edges {
          node {
            id
            sellUnitPrice
            sellUnitTaxPercentage
            product {
              ...Product
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${FeatureFlagsFragmentDoc}
  ${ProductFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetSupplierQuery, Types.GetSupplierQueryVariables>
