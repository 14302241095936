import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const UpdateBuyListDocument = gql`
  mutation UpdateBuyList($input: UpdateCatalogueMutationInput!) {
    updateCatalogue(input: $input) {
      catalogue {
        id
        title
        department {
          id
          name
        }
        account {
          id
          accountName
        }
      }
    }
  }
` as unknown as DocumentNode<Types.UpdateBuyListMutation, Types.UpdateBuyListMutationVariables>
