import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './access_graphql'

export const SignOutDocument = gql`
  mutation SignOut($input: SignOutMutationInput!) {
    signOut(input: $input) {
      user {
        fullName
        email
        id
      }
    }
  }
` as unknown as DocumentNode<Types.SignOutMutation, Types.SignOutMutationVariables>
