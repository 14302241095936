import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { AdvancedRequisitionBodyFragmentDoc } from './advancedRequisitionBody.generated'
import * as Types from './purchasing_graphql'

export const DeleteAdvancedRequisitionLineDocument = gql`
  mutation DeleteAdvancedRequisitionLine($input: DeleteRequisitionLineMutationInput!) {
    deleteRequisitionLine(input: $input) {
      requisition {
        ...AdvancedRequisitionBody
      }
    }
  }
  ${AdvancedRequisitionBodyFragmentDoc}
` as unknown as DocumentNode<
  Types.DeleteAdvancedRequisitionLineMutation,
  Types.DeleteAdvancedRequisitionLineMutationVariables
>
