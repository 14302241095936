import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const UpdateStockLevelDocument = gql`
  mutation UpdateStockLevel($input: UpdateStockLevelInput!) {
    updateStockLevel(input: $input) {
      stockLevel {
        id
        parLevel
        reorderLevel
      }
    }
  }
` as unknown as DocumentNode<Types.UpdateStockLevelMutation, Types.UpdateStockLevelMutationVariables>
