import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetWorkflowRankMembershipsDocument = gql`
  query GetWorkflowRankMemberships($workflowRankId: Int, $before: String, $after: String, $first: Int, $last: Int) {
    workflowRank(id: $workflowRankId) {
      id
      workflowRankMemberships(before: $before, after: $after, first: $first, last: $last) {
        totalCount
        edges {
          node {
            id
            member {
              id
              fullName
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetWorkflowRankMembershipsQuery, Types.GetWorkflowRankMembershipsQueryVariables>
