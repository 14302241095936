import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetInvoiceLineItemDocument = gql`
  query GetInvoiceLineItem($invoiceId: Int, $invoiceLineItemId: Int, $sortSubLines: [RansackSortType!]) {
    currentPurchaser {
      id
      invoice(id: $invoiceId) {
        id
        currency
        invoiceLineItem(id: $invoiceLineItemId) {
          id
          product: originalProduct {
            id
            brand
            itemDescription
            itemSize
            image
            itemMeasure
            itemPackName
            concatenatedSellUnit
          }
          relatedReceivingDocumentLineItems {
            edges {
              node {
                id
                purchaseOrderLineItem {
                  id
                }
                receivingDocument {
                  id
                  receivingDocumentNumber
                  purchaseOrderId
                  receiver {
                    id
                    fullName
                  }
                  receivedDate
                  transfer {
                    id
                    transferNumber
                  }
                }
                receivedQuantity
                unitPrice
                totalValue
                quantity
                receivedToInventory
                stockLocation {
                  id
                  name
                }
                product {
                  id
                  image
                  brand
                  itemDescription
                  itemSize
                  itemMeasure
                  itemPackName
                  concatenatedSellUnit
                }
              }
            }
          }
          relatedInvoiceLineItemsWithSamePo {
            edges {
              node {
                id
                invoice {
                  id
                  invoiceNumber
                }
                purchaseOrderLineItem {
                  id
                }
                quantity
                unitPrice
                taxPercentage
                purchaseOrderLineItem {
                  id
                }
                product: originalProduct {
                  id
                  brand
                  image
                  itemDescription
                  itemSize
                  itemMeasure
                  itemPackName
                  concatenatedSellUnit
                }
              }
            }
          }
          receivedStatusEnum
          totalReceivedQuantity
          totalInvoicedQuantity
          quantity
          unitPrice
          invoicePriceVariance
          taxPercentage
          productMatchedInvoicedQuantity
          productMatchedReceivedQuantity
          productMatchedOrderedQuantity
          productMatchedOrderedPrice
          purchaseOrderLineItem {
            id
            image
            product {
              id
              brand
              image
              itemDescription
              itemSize
              itemMeasure
              itemPackName
              concatenatedSellUnit
            }
            purchaseOrder {
              id
              purchaseOrderNumber
              currency
            }
            quantity
            receivedQuantity
            unitPrice
            taxPercentage
          }
          subLines(sort: $sortSubLines) {
            edges {
              node {
                id
                department {
                  id
                  name
                }
                account {
                  id
                  accountName
                  code
                }
                unitPrice
                tax
                taxRate
                unitTax
                quantity
                totalValue
                lineType
                department {
                  id
                  name
                }
                account {
                  id
                  accountName
                }
                description
                reference
              }
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetInvoiceLineItemQuery, Types.GetInvoiceLineItemQueryVariables>
