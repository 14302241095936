import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BarcodeFragmentDoc } from './Barcode.generated'
import { PageInfoFragmentDoc } from './PageInfo.generated'
import { ProductFragmentDoc } from './Product.generated'
import { SupplierDetailByLineFragmentDoc } from './SupplierDetailByLine.generated'
import * as Types from './purchasing_graphql'

export const GetPricedCatalogueDocument = gql`
  query GetPricedCatalogue(
    $pricedCatalogueId: Int
    $after: String
    $before: String
    $filter: RansackFilterType
    $sort: [RansackSortType!]
    $first: Int
    $last: Int
  ) {
    currentPurchaser {
      id
      pricedCatalogue(id: $pricedCatalogueId) {
        id
        title
        updatedAt
        createdAt
        contracted
        user {
          id
          fullName
        }
        owner {
          id
          ... on Supplier {
            name
            isAPurchaser
            isASupplier
          }
          ... on Purchaser {
            name
            isAPurchaser
            isASupplier
          }
        }
        pricedCataloguedProducts(
          after: $after
          before: $before
          filter: $filter
          sort: $sort
          first: $first
          last: $last
        ) {
          edges {
            node {
              id
              supplierProductCode
              product {
                ...Product
                master
                barcodes {
                  edges {
                    node {
                      ...Barcode
                    }
                  }
                }
                pricings {
                  edges {
                    node {
                      id
                      sellUnitPrice
                      updatedAt
                      sellUnitTaxPercentage
                    }
                  }
                }
              }
              image
              sellUnitPrice
              sellUnitTaxPercentage
              longDescription
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
        supplier {
          ...SupplierDetailByLine
        }
      }
    }
  }
  ${ProductFragmentDoc}
  ${BarcodeFragmentDoc}
  ${PageInfoFragmentDoc}
  ${SupplierDetailByLineFragmentDoc}
` as unknown as DocumentNode<Types.GetPricedCatalogueQuery, Types.GetPricedCatalogueQueryVariables>
