import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetTradeRelationshipsDocument = gql`
  query GetTradeRelationships(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sort: String
    $supplierId: Int
    $searchText: String
    $purchaserId: Int
    $accountsPayableSystemId: Int
  ) {
    tradeRelationships(
      after: $after
      before: $before
      first: $first
      last: $last
      sort: $sort
      supplierId: $supplierId
      searchText: $searchText
      purchaserId: $purchaserId
      accountsPayableSystemId: $accountsPayableSystemId
    ) {
      edges {
        node {
          id
          isEnabled
          isEnabledBySupplier
          status
          purchaser {
            id
            name
            accountsPayableSystem {
              id
              name
            }
          }
          supplier {
            id
            name
          }
          pipes {
            unpaginated {
              type
              id
              isDefault
              externalUrl
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetTradeRelationshipsQuery, Types.GetTradeRelationshipsQueryVariables>
