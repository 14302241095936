import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const CreateStockItemDocument = gql`
  mutation CreateStockItem($input: CreateStockItemInput!) {
    createStockItem(input: $input) {
      stockItem {
        id
        image
        brand
        itemDescription
        itemSize
        itemMeasure
        itemPackName
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.CreateStockItemMutation, Types.CreateStockItemMutationVariables>
