import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const DeletePricedCatalogueDocument = gql`
  mutation DeletePricedCatalogue($input: DeletePricedCatalogueMutationInput!) {
    deletePricedCatalogue(input: $input) {
      success
    }
  }
` as unknown as DocumentNode<Types.DeletePricedCatalogueMutation, Types.DeletePricedCatalogueMutationVariables>
