import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const UpdatePurchaseOrderDocument = gql`
  mutation UpdatePurchaseOrder($input: UpdatePurchaseOrderMutationInput!) {
    updatePurchaseOrder(input: $input) {
      purchaseOrder {
        id
        expectedDeliveryDate
        currency
        organisationUnit {
          id
          name
        }
        account {
          id
          accountName
          code
        }
        department {
          id
          name
        }
        deliveryAddress {
          id
          locationName
          line1
          line2
          city
          stateProvince
          country
        }
        sendToSupplier
        taxCodeInfo
        taxCodeId
      }
    }
  }
` as unknown as DocumentNode<Types.UpdatePurchaseOrderMutation, Types.UpdatePurchaseOrderMutationVariables>
