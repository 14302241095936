import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BarcodeFragmentDoc } from './Barcode.generated'
import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasing_graphql'

export const UpdateBarcodeDocument = gql`
  mutation UpdateBarcode($input: UpdateBarcodeMutationInput!) {
    updateBarcode(input: $input) {
      barcode {
        id
        product {
          id
          barcodes {
            edges {
              node {
                ...Barcode
              }
            }
          }
        }
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BarcodeFragmentDoc}
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.UpdateBarcodeMutation, Types.UpdateBarcodeMutationVariables>
