import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetWorkflowRanksDocument = gql`
  query GetWorkflowRanks($workflowId: Int, $after: String, $before: String, $first: Int, $last: Int) {
    workflow(id: $workflowId) {
      id
      workflowRanks(after: $after, before: $before, first: $first, last: $last) {
        edges {
          node {
            id
            position
            name
            maximumValue
            workflowRankMemberships {
              totalCount
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetWorkflowRanksQuery, Types.GetWorkflowRanksQueryVariables>
