import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetTradeRelationshipPipesDocument = gql`
  query GetTradeRelationshipPipes(
    $tradeRelationshipId: Int!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    tradeRelationship(id: $tradeRelationshipId) {
      id
      pipes(after: $after, before: $before, first: $first, last: $last) {
        edges {
          node {
            id
            type
            externalUrl
            exportFormat
            attachmentFormat
            createdAt
            isDefault
            owner {
              id
              name
            }
            tradeRelationships {
              totalCount
              unpaginated {
                id
                isEnabled
                isEnabledBySupplier
                status
                purchaser {
                  id
                  name
                  accountsPayableSystem {
                    id
                    name
                  }
                }
                supplier {
                  id
                  name
                }
              }
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetTradeRelationshipPipesQuery, Types.GetTradeRelationshipPipesQueryVariables>
