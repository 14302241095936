import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasing_graphql'

export const ProductSupplierPreferencesDocument = gql`
  query ProductSupplierPreferences(
    $filter: RansackFilterType
    $first: Int
    $last: Int
    $before: String
    $after: String
    $sortPrices: [RansackSortType!]
  ) {
    currentPurchaser {
      id
      productSupplierPreferences(filter: $filter, first: $first, last: $last, before: $before, after: $after) {
        edges {
          node {
            id
            enabled
            purchaserId
            supplier {
              id
              name
            }
            product {
              id
              productCode
              image
              category {
                id
                name
                parent {
                  id
                  name
                }
              }
              brand
              itemDescription
              concatenatedDescription
              concatenatedSellUnit
              itemPackName
              itemMeasure
              itemSellPackName
              itemSize
              image
              owner {
                id
                name
              }
              canonicalProductId
              prices(sort: $sortPrices) {
                edges {
                  node {
                    unitPrice
                    supplierName
                    taxPercentage
                    blacklisted
                  }
                }
              }
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.ProductSupplierPreferencesQuery, Types.ProductSupplierPreferencesQueryVariables>
