import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './inventory_graphql'

export const GetAllLegacyStockLocationsDocument = gql`
  query GetAllLegacyStockLocations(
    $organisationId: Int!
    $after: String
    $first: Int
    $filter: RansackFilterType
    $sort: [RansackSortType!]
  ) {
    legalHolder(organisationId: $organisationId) {
      id
      stockLocations(after: $after, first: $first, filter: $filter, sort: $sort) {
        edges {
          node {
            id
            name
            balanceValue
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetAllLegacyStockLocationsQuery, Types.GetAllLegacyStockLocationsQueryVariables>
