import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetRecipeIngredientsDocument = gql`
  query GetRecipeIngredients(
    $recipeId: Int!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sorts: [RecipeLineItemsQuerySortsInput!]
    $filters: RecipeLineItemsQueryFiltersInput
  ) {
    recipe(id: $recipeId) {
      id
      costPercentage
      liveCostPrice
      stockedCostPrice
      lineItems(after: $after, before: $before, first: $first, last: $last, sorts: $sorts, filters: $filters) {
        edges {
          node {
            id
            portion
            cost
            ingredient {
              id
              image
              itemDescription
              brand
              productCode
              itemMeasure
              itemSize
              convertibleMeasures
              owner {
                id
                name
              }
            }
            stockedCostPrice
            measure
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetRecipeIngredientsQuery, Types.GetRecipeIngredientsQueryVariables>
