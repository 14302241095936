import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { ProductFragmentDoc } from './Product.generated'
import { SupplierDetailByLineFragmentDoc } from './SupplierDetailByLine.generated'
import * as Types from './purchasing_graphql'

export const GetRequisitionLinesBySuppliersDocument = gql`
  query GetRequisitionLinesBySuppliers($requisitionId: Int, $nextGenQuotes: Boolean) {
    currentPurchaser {
      id
      requisition(id: $requisitionId) {
        id
        totalValueExcludingTax
        totalTaxValue
        totalValue
        requisitionNumber
        reference
        currency
        requisitionLinesBySuppliers {
          supplier {
            ...SupplierDetailByLine
            supplierRelationship {
              id
              preferredSupplier
            }
          }
          total
          subTotalExTax
          totalTax
          lines {
            id
            uuid
            quantity
            unitPrice
            unitTax
            cataloguedProductId
            image
            taxPercentage
            orderInstruction
            supplierProductCode
            purchaserProductCode
            longDescription
            tax
            taxPercentage
            total
            supplierProductCode
            purchaserProductCode
            supplier {
              id
              name
            }
            livePremium(nextGenQuotes: $nextGenQuotes)
            availableQuotes(nextGenQuotes: $nextGenQuotes) {
              unitPrice
              taxPercentage
              supplierName
              supplierId
              cataloguedProductId
              preferredSupplier
              contracted
            }
            product {
              ...Product
            }
            department {
              id
              name
            }
            account {
              id
              accountName
              code
            }
          }
        }
      }
    }
  }
  ${SupplierDetailByLineFragmentDoc}
  ${ProductFragmentDoc}
` as unknown as DocumentNode<
  Types.GetRequisitionLinesBySuppliersQuery,
  Types.GetRequisitionLinesBySuppliersQueryVariables
>
