import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const StockItemFragmentDoc = gql`
  fragment StockItem on StockItem {
    id
    image
    itemDescription
    itemMeasure
    itemPackName
    itemSellPackName
    itemSellQuantity
    itemSize
    externalId
    brand
    productCode
    ownerProductCode
    canonicalProductId
    stockItem
    countBy
    averageUnitValue
    totalBalanceValue
    totalBalanceQuantity
    archivable
    category {
      id
      name
      parent {
        id
        name
      }
    }
    owner {
      id
      name
    }
    barcodes {
      edges {
        node {
          id
          body
          isDefault
          organisation {
            id
            name
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.StockItemFragment, unknown>
