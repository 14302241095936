import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CancelLegacyReceivingDocumentDocument = gql`
  mutation CancelLegacyReceivingDocument($input: CancelReceivingDocumentMutationInput!) {
    cancelReceivingDocument(input: $input) {
      receivingDocument {
        id
        purchaseOrder {
          id
          purchaseOrderNumber
        }
        status
      }
    }
  }
` as unknown as DocumentNode<
  Types.CancelLegacyReceivingDocumentMutation,
  Types.CancelLegacyReceivingDocumentMutationVariables
>
