import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const OrganisationFragmentDoc = gql`
  fragment Organisation on Organisation {
    id
    name
    businessName
    businessNumber
    businessUnit
    email
    telephone
    departmentCode
    corporateCode
    companyBrand
    postalAddressLine1
    postalAddressLine2
    postalAddressCountry
    postalAddressCity
    postalAddressStateProvince
    postalAddressPostalCode
    billingAddressLine1
    billingAddressLine2
    billingAddressCountry
    billingAddressCity
    billingAddressStateProvince
    billingAddressPostalCode
    billingAddressIsPostalAddress
  }
` as unknown as DocumentNode<Types.OrganisationFragment, unknown>
