import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetPurchaseOrderReceivingNotesDocument = gql`
  query GetPurchaseOrderReceivingNotes($purchaseOrderId: Int, $sort: [RansackSortType!]) {
    currentPurchaser {
      id
      purchaseOrder(id: $purchaseOrderId) {
        id
        purchaseOrderNumber
        outstandingTotal
        receivedTotal
        totalValue
        statusEnum
        totalValueExcludingTax
        currency
        receivingDocuments(sort: $sort) {
          edges {
            node {
              id
              receivingDocumentNumber
              receivedDate
              status
              destinationId
              purchaseOrderId
              receiver {
                id
                fullName
              }
              destination {
                id
                name
              }
              transfer {
                id
                transferNumber
              }
              receivingTotalValue
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.GetPurchaseOrderReceivingNotesQuery,
  Types.GetPurchaseOrderReceivingNotesQueryVariables
>
