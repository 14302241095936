import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BarcodeFragmentDoc } from './Barcode.generated'
import { PageInfoFragmentDoc } from './PageInfo.generated'
import { ProductFragmentDoc } from './Product.generated'
import * as Types from './purchasing_graphql'

export const GetAvailableProductsDocument = gql`
  query GetAvailableProducts(
    $searchText: String
    $sortPrices: [RansackSortType!]
    $after: String
    $categoryIds: [Int!]
    $supplierIds: [Int!]
    $hasQuotes: Boolean
    $supplierProductCode: String
    $purchaserProductCode: String
    $productId: Int
    $master: Boolean
    $invoiceId: Int!
    $catalogueId: Int!
    $requisitionId: Int!
    $first: Int
    $excludeCatalogueId: Int
    $sort: [RansackSortType!]
    $barcode: String
    $sellQuantity: Float
    $ownerId: Int
    $single: Boolean
    $nextGenQuotes: Boolean
  ) {
    currentPurchaser {
      id
      availableProducts(
        searchText: $searchText
        after: $after
        categoryIds: $categoryIds
        supplierIds: $supplierIds
        hasQuotes: $hasQuotes
        supplierProductCode: $supplierProductCode
        purchaserProductCode: $purchaserProductCode
        productId: $productId
        master: $master
        first: $first
        excludeCatalogueId: $excludeCatalogueId
        sort: $sort
        barcode: $barcode
        sellQuantity: $sellQuantity
        ownerId: $ownerId
        single: $single
        nextGenQuotes: $nextGenQuotes
      ) {
        edges {
          node {
            ...Product
            inBuyListCount
            productCode
            invoiced(invoiceId: $invoiceId)
            catalogued(catalogueId: $catalogueId)
            requisitioned(requisitionId: $requisitionId)
            stockItem
            barcodes {
              edges {
                node {
                  ...Barcode
                }
              }
            }
            prices(sort: $sortPrices) {
              edges {
                node {
                  unitPrice
                  catalogueId
                  supplierName
                  taxPercentage
                  contracted
                }
              }
            }
            catalogues {
              edges {
                node {
                  id
                  title
                }
              }
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${ProductFragmentDoc}
  ${BarcodeFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAvailableProductsQuery, Types.GetAvailableProductsQueryVariables>
