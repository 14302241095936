import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const ResendInvoiceDocument = gql`
  mutation ResendInvoice($input: ResendInvoiceMutationInput!) {
    resendInvoice(input: $input) {
      invoice {
        id
        status
      }
    }
  }
` as unknown as DocumentNode<Types.ResendInvoiceMutation, Types.ResendInvoiceMutationVariables>
