import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { FeatureFlagsFragmentDoc } from './FeatureFlags.generated'
import { FinanceFlagsFragmentDoc } from './FinanceFlags.generated'
import { InvoiceThresholdFragmentDoc } from './InvoiceThreshold.generated'
import * as Types from './purchasePlus_graphql'

export const GetOrganisationDocument = gql`
  query GetOrganisation($organisationId: Int!) {
    organisation(id: $organisationId) {
      id
      name
      businessName
      businessNumber
      businessUnit
      email
      telephone
      departmentCode
      corporateCode
      companyBrand
      postalAddressLine1
      postalAddressLine2
      postalAddressCountry
      postalAddressCity
      postalAddressStateProvince
      postalAddressPostalCode
      billingAddressLine1
      billingAddressLine2
      billingAddressCountry
      billingAddressCity
      billingAddressStateProvince
      billingAddressPostalCode
      billingAddressIsPostalAddress
      legalEntity
      createdAt
      emailApiInvoiceCopy
      featureFlags {
        ...FeatureFlags
      }
      isASupplier
      isAPurchaser
      parent {
        id
        name
      }
      owner {
        id
        name
      }
      isVerified
      ... on Purchaser {
        accountsPayableSystem {
          id
          name
          financeExportFormat {
            id
            name
          }
          financeExportVariant {
            id
            name
          }
        }
        paperlessInvoicingIdentityEmail
        holder {
          id
          purchaser {
            id
            name
          }
        }
        invoiceThresholds {
          edges {
            node {
              ...InvoiceThreshold
            }
          }
        }
        financeFlagSettings {
          ...FinanceFlags
        }
      }
    }
  }
  ${FeatureFlagsFragmentDoc}
  ${InvoiceThresholdFragmentDoc}
  ${FinanceFlagsFragmentDoc}
` as unknown as DocumentNode<Types.GetOrganisationQuery, Types.GetOrganisationQueryVariables>
