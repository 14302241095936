import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetDepartmentsDocument = gql`
  query GetDepartments($purchaserId: Int) {
    purchaser(id: $purchaserId) {
      id
      departments {
        id
        name
        legalEntity
        departmentCode
        defaultAccount {
          name
        }
        parent {
          id
          name
        }
        departments {
          id
          name
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetDepartmentsQuery, Types.GetDepartmentsQueryVariables>
