import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const DeleteCreditNoteDocument = gql`
  mutation DeleteCreditNote($input: DeleteCreditNoteMutationInput!) {
    deleteCreditNote(input: $input) {
      success
    }
  }
` as unknown as DocumentNode<Types.DeleteCreditNoteMutation, Types.DeleteCreditNoteMutationVariables>
