import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetTransferDocument = gql`
  query GetTransfer($transferId: Int!) {
    transfer(id: $transferId) {
      id
      createdAt
      destination {
        id
        name
        type
      }
      origin {
        id
        name
        type
      }
      reference
      status
      transferNumber
      type
      updatedAt
    }
  }
` as unknown as DocumentNode<Types.GetTransferQuery, Types.GetTransferQueryVariables>
