import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetCurrentPurchaserDocument = gql`
  query GetCurrentPurchaser {
    currentPurchaser {
      id
      name
      isAPurchaser
      isInventoryEnabled
      ppnewAccess
      currency
    }
  }
` as unknown as DocumentNode<Types.GetCurrentPurchaserQuery, Types.GetCurrentPurchaserQueryVariables>
