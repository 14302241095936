import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { FlaggingMessageFragmentDoc } from './FlaggingMessage.generated'
import { ProductFragmentDoc } from './Product.generated'
import * as Types from './purchasing_graphql'

export const UpdateInvoiceLineItemDocument = gql`
  mutation UpdateInvoiceLineItem($input: UpdateInvoiceLineItemMutationInput!) {
    updateInvoiceLineItem(input: $input) {
      invoiceLineItem {
        id
        quantity
        unitPrice
        taxPercentage
        orderInstruction
        lineTotal
        lineTax
        originalLineTotal
        receivedStatusEnum
        orderedQuantity
        totalReceivedQuantity
        totalInvoicedQuantity
        productMatchedInvoicedQuantity
        productMatchedReceivedQuantity
        productMatchedOrderedQuantity
        productMatchedOrderedPrice
        purchaseOrderLineItemId
        subLines {
          edges {
            node {
              id
              department {
                id
                name
              }
              account {
                id
                accountName
                code
              }
              unitPrice
              tax
              taxRate
              quantity
              totalValue
              lineType
              department {
                id
                name
              }
              account {
                id
                accountName
              }
              description
              reference
              customJson
            }
          }
        }
        purchaseOrderLineItem {
          id
          quantity
          unitPrice
          taxPercentage
          procurementProductId
          purchaseOrder {
            id
            currency
          }
          product {
            id
            category {
              id
              name
              parent {
                id
                name
              }
            }
            brand
            image
            itemDescription
            concatenatedSellUnit
            itemPackName
            itemMeasure
            itemSellPackName
            itemSize
          }
        }
        originalProductId
        invoiceId
        product: originalProduct {
          ...Product
        }
        flaggingMessages {
          ...FlaggingMessage
        }
        invoice {
          id
          lineTaxTotal
          lineTotal
          outOfBalance
          hasLinkedLines
          flaggingMessages {
            ...FlaggingMessage
          }
        }
      }
    }
  }
  ${ProductFragmentDoc}
  ${FlaggingMessageFragmentDoc}
` as unknown as DocumentNode<Types.UpdateInvoiceLineItemMutation, Types.UpdateInvoiceLineItemMutationVariables>
