import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CreateAiScanningEmailAddressDocument = gql`
  mutation CreateAiScanningEmailAddress($input: CreateAiScanningEmailAddressInput!) {
    createAiScanningEmailAddress(input: $input) {
      aiScanningEmailAddress {
        id
      }
    }
  }
` as unknown as DocumentNode<
  Types.CreateAiScanningEmailAddressMutation,
  Types.CreateAiScanningEmailAddressMutationVariables
>
