import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetInvoiceThresholdsDocument = gql`
  query GetInvoiceThresholds {
    currentPurchaser {
      id
      invoiceThresholds {
        edges {
          node {
            id
            invoiceTotalPercentage
            invoiceTotalUseHigher
            invoiceTotalValue
            lineQuantityPercentage
            lineQuantityUseHigher
            lineQuantityValue
            unitPricePercentage
            unitPriceUseHigher
            unitPriceValue
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetInvoiceThresholdsQuery, Types.GetInvoiceThresholdsQueryVariables>
