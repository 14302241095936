import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const CreateInvoiceWithLinesDocument = gql`
  mutation CreateInvoiceWithLines($input: CreateInvoiceWithLinesInput!) {
    createInvoiceWithLines(input: $input) {
      invoice {
        id
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.CreateInvoiceWithLinesMutation, Types.CreateInvoiceWithLinesMutationVariables>
