import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const CreateWorkflowRankMembershipDocument = gql`
  mutation CreateWorkflowRankMembership($input: CreateWorkflowRankMembershipInput!) {
    createWorkflowRankMembership(input: $input) {
      workflowRankMembership {
        id
        workflowRank {
          id
          workflowRankMemberships {
            totalCount
          }
        }
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<
  Types.CreateWorkflowRankMembershipMutation,
  Types.CreateWorkflowRankMembershipMutationVariables
>
