import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const DeliveryFragmentDoc = gql`
  fragment Delivery on Delivery {
    id
    state
    createdAt
    endpoint
    contentLink
    sender {
      id
      fullName
    }
  }
` as unknown as DocumentNode<Types.DeliveryFragment, unknown>
