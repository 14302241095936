import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateBuyListExportDocument = gql`
  mutation CreateBuyListExport($input: CreateCatalogueExportMutationInput!) {
    createCatalogueExport(input: $input) {
      catalogueExport {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.CreateBuyListExportMutation, Types.CreateBuyListExportMutationVariables>
