import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const CreateFinanceExportExecutionDocument = gql`
  mutation CreateFinanceExportExecution($input: CreateFinanceExportExecutionInput!) {
    createFinanceExportExecution(input: $input) {
      financeExportExecution {
        id
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<
  Types.CreateFinanceExportExecutionMutation,
  Types.CreateFinanceExportExecutionMutationVariables
>
