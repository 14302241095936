import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetFinanceExportExecutionCreditNotesDocument = gql`
  query GetFinanceExportExecutionCreditNotes($id: Int!, $after: String, $before: String, $first: Int, $last: Int) {
    financeExportExecution(id: $id) {
      id
      creditNotes(after: $after, before: $before, first: $first, last: $last) {
        edges {
          node {
            id
            status
            creditNoteNumber
            totalValue
          }
        }
        totalCount
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<
  Types.GetFinanceExportExecutionCreditNotesQuery,
  Types.GetFinanceExportExecutionCreditNotesQueryVariables
>
