import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CancelRequisitionDocument = gql`
  mutation CancelRequisition($input: CancelRequisitionMutationInput!) {
    cancelRequisition(input: $input) {
      requisition {
        id
        status
      }
    }
  }
` as unknown as DocumentNode<Types.CancelRequisitionMutation, Types.CancelRequisitionMutationVariables>
