import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetFinanceExportExecutionsDocument = gql`
  query GetFinanceExportExecutions($after: String, $before: String, $first: Int, $last: Int, $exportNumber: String) {
    financeExportExecutions(after: $after, before: $before, first: $first, last: $last, exportNumber: $exportNumber) {
      edges {
        node {
          id
          exportNumber
          user {
            id
            fullName
          }
          status
          createdAt
          downloadUrl
          dateRange
          completedAt
          customSelection
          documentType
          purchaser {
            id
            name
          }
          email
          startDate
          endDate
          financeExportVariant {
            id
            name
          }
          financeExportFormat {
            id
            name
          }
          electronicDocumentSentEvent {
            id
            payloadFileName
            payloadUrl
            response
            responseLogUrl
            status
            electronicDocumentDestination {
              id
              name
              pipe
              externalUrl
            }
          }
          markDocumentsExported
          useExportReadyDocuments
          invoices {
            totalCount
          }
          creditNotes {
            totalCount
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetFinanceExportExecutionsQuery, Types.GetFinanceExportExecutionsQueryVariables>
