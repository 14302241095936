import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { AdvancedRequisitionBodyFragmentDoc } from './advancedRequisitionBody.generated'
import * as Types from './purchasing_graphql'

export const CreateAdvancedRequisitionLineDocument = gql`
  mutation CreateAdvancedRequisitionLine($input: CreateRequisitionLineMutationInput!) {
    createRequisitionLine(input: $input) {
      requisition {
        ...AdvancedRequisitionBody
      }
    }
  }
  ${AdvancedRequisitionBodyFragmentDoc}
` as unknown as DocumentNode<
  Types.CreateAdvancedRequisitionLineMutation,
  Types.CreateAdvancedRequisitionLineMutationVariables
>
