import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const ApproveRequisitionsDocument = gql`
  mutation ApproveRequisitions($input: ApproveRequisitionsInput!) {
    approveRequisitions(input: $input) {
      errors {
        ...BaseError
      }
      failed {
        errors {
          ...BaseError
        }
        requisition {
          id
          requisitionNumber
          status
        }
      }
      processed {
        id
        requisitionNumber
        status
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.ApproveRequisitionsMutation, Types.ApproveRequisitionsMutationVariables>
