import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const UpdateProductSupplierPreferenceDocument = gql`
  mutation UpdateProductSupplierPreference($input: UpdateProductSupplierPreferenceMutationInput!) {
    updateProductSupplierPreference(input: $input) {
      productSupplierPreference {
        id
        productId
        supplierId
        purchaserId
        enabled
      }
    }
  }
` as unknown as DocumentNode<
  Types.UpdateProductSupplierPreferenceMutation,
  Types.UpdateProductSupplierPreferenceMutationVariables
>
