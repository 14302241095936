import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetInvoiceReceivingNotesDocument = gql`
  query GetInvoiceReceivingNotes($invoiceId: Int, $sort: [RansackSortType!]) {
    currentPurchaser {
      id
      invoice(id: $invoiceId) {
        id
        invoiceNumber
        purchaseOrderId
        currency
        purchaseOrder {
          id
          statusEnum
        }
        receivingDocuments(sort: $sort) {
          edges {
            node {
              id
              receivingDocumentNumber
              receivedDate
              status
              destinationId
              purchaseOrderId
              receiver {
                id
                fullName
              }
              destination {
                id
                name
              }
              transfer {
                id
                transferNumber
              }
              receivingTotalValue
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetInvoiceReceivingNotesQuery, Types.GetInvoiceReceivingNotesQueryVariables>
