import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetPurchaseOrderInvoicesDocument = gql`
  query GetPurchaseOrderInvoices($purchaseOrderId: Int) {
    currentPurchaser {
      id
      purchaseOrder(id: $purchaseOrderId) {
        id
        purchaseOrderNumber
        currency
        invoices {
          edges {
            node {
              id
              invoiceNumber
              invoiceDate
              status
              totalTaxValue
              totalValue
              reference
              currency
              creator {
                id
                fullName
              }
              financialApprovers {
                edges {
                  node {
                    id
                    createdAt
                    user {
                      id
                      fullName
                    }
                  }
                }
              }
              operationalApprovers {
                edges {
                  node {
                    id
                    createdAt
                    user {
                      id
                      fullName
                    }
                  }
                }
              }
              department {
                id
                name
              }
              account {
                id
                accountName
                code
              }
              purchaseOrder {
                id
                purchaseOrderNumber
                requisition {
                  id
                  reference
                }
              }
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetPurchaseOrderInvoicesQuery, Types.GetPurchaseOrderInvoicesQueryVariables>
