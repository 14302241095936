import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const UpdateContractCataloguedProductDocument = gql`
  mutation UpdateContractCataloguedProduct($input: UpdateContractCataloguedProductMutationInput!) {
    updateContractCataloguedProduct(input: $input) {
      contractCataloguedProduct {
        id
        sellUnitPrice
        sellUnitTaxPercentage
      }
    }
  }
` as unknown as DocumentNode<
  Types.UpdateContractCataloguedProductMutation,
  Types.UpdateContractCataloguedProductMutationVariables
>
