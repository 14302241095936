import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateRequisitionDocument = gql`
  mutation CreateRequisition($input: CreateRequisitionMutationInput!) {
    createRequisition(input: $input) {
      requisition {
        id
        advanced
      }
    }
  }
` as unknown as DocumentNode<Types.CreateRequisitionMutation, Types.CreateRequisitionMutationVariables>
