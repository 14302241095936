import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        fullName
        email
        telephone
        staffCode
        autoSendPurchaseOrder
        enforceBuyList
        createdAt
        lastSignInAt
        position
        isDeleted
        enableMultiFactorAuthentication
        defaultOrganisation {
          id
          name
        }
        preferences {
          viewPreference
          allowInvoiceEditing
          expertMode
          defaultLanguage
        }
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>
