import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateCataloguedProductDocument = gql`
  mutation CreateCataloguedProduct($input: CreateCataloguedProductMutationInput!) {
    createCataloguedProduct(input: $input) {
      cataloguedProduct {
        id
        product {
          id
        }
      }
    }
  }
` as unknown as DocumentNode<Types.CreateCataloguedProductMutation, Types.CreateCataloguedProductMutationVariables>
