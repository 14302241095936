import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetPurchaseOrderLinesLinkableDocument = gql`
  query GetPurchaseOrderLinesLinkable($invoiceId: ID!, $purchaseOrderId: Int) {
    currentPurchaser {
      id
      purchaseOrder(id: $purchaseOrderId) {
        id
        lineItemsNotInvoicedBy(invoiceId: $invoiceId) {
          id
          quantity
          unitPrice
          taxPercentage
          product {
            id
            category {
              id
              name
              parent {
                id
                name
              }
            }
            brand
            itemDescription
            concatenatedSellUnit
            itemPackName
            itemMeasure
            itemSellPackName
            itemSize
          }
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.GetPurchaseOrderLinesLinkableQuery,
  Types.GetPurchaseOrderLinesLinkableQueryVariables
>
