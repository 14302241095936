import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './access_graphql'

export const GetUserDocument = gql`
  query GetUser($userId: Int, $after: String, $before: String, $first: Int, $last: Int) {
    user(id: $userId) {
      id
      fullName
      email
      telephone
      staffCode
      autoSendPurchaseOrder
      enforceBuyList
      createdAt
      currentSignInAt
      position
      isDeleted
      isAdmin
      confirmedAt
      defaultOrganisationId
      enableMultiFactorAuthentication
      defaultOrganisation {
        id
        name
      }
      preferences {
        viewPreference
        allowInvoiceEditing
        expertMode
        defaultLanguage
      }
      userOrganisations(after: $after, before: $before, first: $first, last: $last) {
        edges {
          node {
            id
            organisation {
              id
              name
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetUserQuery, Types.GetUserQueryVariables>
