import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetSupplierPublicDetailsDocument = gql`
  query GetSupplierPublicDetails($supplierId: Int!) {
    supplier(id: $supplierId) {
      id
      name
      businessNumber
      telephone
      email
      owner {
        id
        name
      }
      billingAddressLine1
      billingAddressLine2
      billingAddressCity
      billingAddressStateProvince
      billingAddressPostalCode
      billingAddressCountry
      postalAddressLine1
      postalAddressLine2
      postalAddressCity
      postalAddressStateProvince
      postalAddressPostalCode
      postalAddressCountry
    }
  }
` as unknown as DocumentNode<Types.GetSupplierPublicDetailsQuery, Types.GetSupplierPublicDetailsQueryVariables>
