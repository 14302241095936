import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const UpdateStockLocationDocument = gql`
  mutation UpdateStockLocation($input: UpdateStockLocationInput!) {
    updateStockLocation(input: $input) {
      stockLocation {
        id
        name
      }
    }
  }
` as unknown as DocumentNode<Types.UpdateStockLocationMutation, Types.UpdateStockLocationMutationVariables>
