import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetReportDocument = gql`
  query GetReport($reportId: Int!) {
    report(id: $reportId) {
      id
      availableColumns
      dateRangeFieldName
      defaultColumns
      defaultDateRange
      custom
      owner {
        id
        name
      }
      maximumDateRange
      reportClassName
      reportName
      reportShortId
      showTypeArgument
      showStatusArgument
      showOwnershipArgument
      showDateRangeArgument
      rootFieldName
      name
      gqlQuery
    }
  }
` as unknown as DocumentNode<Types.GetReportQuery, Types.GetReportQueryVariables>
