import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetAiScanningJobsDocument = gql`
  query GetAiScanningJobs(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filters: AiScanningJobsQueryFiltersInput
    $sorts: [AiScanningJobsQuerySortsInput!]
  ) {
    aiScanningJobs(after: $after, before: $before, first: $first, last: $last, filters: $filters, sorts: $sorts) {
      edges {
        node {
          id
          documentUrl
          state
          supplierName
          user {
            id
            fullName
          }
          organisation {
            id
            name
          }
          createdAt
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAiScanningJobsQuery, Types.GetAiScanningJobsQueryVariables>
