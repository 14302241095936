import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetCurrentPurchaserAccountsDocument = gql`
  query GetCurrentPurchaserAccounts {
    currentPurchaser {
      id
      accountsInTree {
        id
        accountName
      }
    }
  }
` as unknown as DocumentNode<Types.GetCurrentPurchaserAccountsQuery, Types.GetCurrentPurchaserAccountsQueryVariables>
