import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const SendPurchaseOrdersDocument = gql`
  mutation SendPurchaseOrders($input: SendPurchaseOrdersInput!) {
    sendPurchaseOrders(input: $input) {
      errors {
        ...BaseError
      }
      processed {
        id
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.SendPurchaseOrdersMutation, Types.SendPurchaseOrdersMutationVariables>
