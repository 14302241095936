import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const BuildRequisitionFromDocumentDocument = gql`
  mutation BuildRequisitionFromDocument($input: BuildRequisitionFromDocumentMutationInput!) {
    buildRequisitionFromDocument(input: $input) {
      requisition {
        id
        advanced
      }
    }
  }
` as unknown as DocumentNode<
  Types.BuildRequisitionFromDocumentMutation,
  Types.BuildRequisitionFromDocumentMutationVariables
>
