import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { FlaggingMessageFragmentDoc } from './FlaggingMessage.generated'
import { PageInfoFragmentDoc } from './PageInfo.generated'
import { ProductFragmentDoc } from './Product.generated'
import * as Types from './purchasing_graphql'

export const GetInvoiceLineItemsDocument = gql`
  query GetInvoiceLineItems(
    $invoiceId: Int
    $sortSubLines: [RansackSortType!]
    $sortLines: [RansackSortType!]
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: RansackFilterType
    $organisationId: Int
  ) {
    currentPurchaser {
      id
      invoice(id: $invoiceId) {
        id
        invoiceLineItems(
          sort: $sortLines
          after: $after
          before: $before
          first: $first
          last: $last
          filter: $filter
        ) {
          edges {
            node {
              id
              quantity
              unitPrice
              taxPercentage
              orderInstruction
              lineTotal
              lineTax
              originalLineTotal
              receivedStatusEnum
              orderedQuantity
              totalReceivedQuantity
              totalInvoicedQuantity
              productMatchedInvoicedQuantity
              productMatchedReceivedQuantity
              productMatchedOrderedQuantity
              productMatchedOrderedPrice
              purchaseOrderLineItemId
              subLines(sort: $sortSubLines) {
                edges {
                  node {
                    id
                    department {
                      id
                      name
                    }
                    account {
                      id
                      accountName
                      code
                    }
                    unitPrice
                    tax
                    taxRate
                    quantity
                    totalValue
                    lineType
                    department {
                      id
                      name
                    }
                    account {
                      id
                      accountName
                    }
                    description
                    reference
                    customJson
                  }
                }
              }
              purchaseOrderLineItem {
                id
                quantity
                unitPrice
                taxPercentage
                procurementProductId
                purchaseOrder {
                  id
                  currency
                }
                product {
                  id
                  category {
                    id
                    name
                    parent {
                      id
                      name
                    }
                  }
                  brand
                  image
                  itemDescription
                  concatenatedSellUnit
                  itemPackName
                  itemMeasure
                  itemSellPackName
                  itemSize
                }
              }
              originalProductId
              invoiceId
              product: originalProduct {
                ...Product
                supplierCode: productCode(organisationId: $organisationId)
                purchaserCode: productCode
              }
              flaggingMessages {
                ...FlaggingMessage
              }
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${ProductFragmentDoc}
  ${FlaggingMessageFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetInvoiceLineItemsQuery, Types.GetInvoiceLineItemsQueryVariables>
