import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './access_graphql'

export const UpdateUserLegacyDocument = gql`
  mutation UpdateUserLegacy($input: UpdateUserMutationInput!) {
    updateUser(input: $input) {
      user {
        id
        fullName
        email
        telephone
        staffCode
        autoSendPurchaseOrder
        enforceBuyList
        createdAt
        lastSignInAt
        position
        isDeleted
        defaultOrganisationId
        enableMultiFactorAuthentication
        defaultOrganisation {
          id
          name
        }
        preferences {
          viewPreference
          allowInvoiceEditing
          expertMode
          defaultLanguage
        }
      }
    }
  }
` as unknown as DocumentNode<Types.UpdateUserLegacyMutation, Types.UpdateUserLegacyMutationVariables>
