import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CreatePurchaseOrderDeliveryEventDocument = gql`
  mutation CreatePurchaseOrderDeliveryEvent($input: CreatePurchaseOrderDeliveryEventInput!) {
    createPurchaseOrderDeliveryEvent(input: $input) {
      delivery {
        id
        endpoint
        contentLink
        state
        createdAt
        sender {
          id
          fullName
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.CreatePurchaseOrderDeliveryEventMutation,
  Types.CreatePurchaseOrderDeliveryEventMutationVariables
>
