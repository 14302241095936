import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasing_graphql'

export const GetAllPurchasersDocument = gql`
  query GetAllPurchasers($last: Int, $first: Int, $filter: RansackFilterType, $before: String, $after: String) {
    currentLegalEntity {
      ... on Supplier {
        id
        purchasers(last: $last, first: $first, filter: $filter, before: $before, after: $after) {
          edges {
            node {
              id
              name
              telephone
              businessName
              email
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAllPurchasersQuery, Types.GetAllPurchasersQueryVariables>
