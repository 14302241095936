import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import { FlaggingMessageFragmentDoc } from './FlaggingMessage.generated'
import * as Types from './purchasing_graphql'

export const UpdateInvoiceDocument = gql`
  mutation UpdateInvoice($input: UpdateInvoiceMutationInput!) {
    updateInvoice(input: $input) {
      invoice {
        id
        adjustmentExTax
        adjustmentTax
        deliveryChargeTax
        deliveryChargeExTax
        totalTaxValue
        totalValue
        totalValueExTax
        outOfBalance
        reference
        invoiceNumber
        invoiceDate
        departmentId
        accountId
        currency
        customJson
        taxCodeId
        taxCodeInfo
        organisationUnit {
          id
          name
        }
        department {
          id
          name
        }
        account {
          id
          accountName
          code
        }
        flaggingMessages {
          ...FlaggingMessage
        }
        flaggedInvoiceLines {
          edges {
            node {
              id
            }
          }
        }
        lines {
          edges {
            node {
              id
              flaggingMessages {
                ...FlaggingMessage
              }
            }
          }
        }
      }
      errors {
        ...BaseError
      }
    }
  }
  ${FlaggingMessageFragmentDoc}
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.UpdateInvoiceMutation, Types.UpdateInvoiceMutationVariables>
