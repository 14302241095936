import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateRequisitionAttachmentDocument = gql`
  mutation CreateRequisitionAttachment($input: CreateRequisitionAttachmentMutationInput!) {
    createRequisitionAttachment(input: $input) {
      requisitionAttachment {
        id
      }
    }
  }
` as unknown as DocumentNode<
  Types.CreateRequisitionAttachmentMutation,
  Types.CreateRequisitionAttachmentMutationVariables
>
