import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const UpdateWorkflowRankDocument = gql`
  mutation UpdateWorkflowRank($input: UpdateWorkflowRankInput!) {
    updateWorkflowRank(input: $input) {
      workflowRank {
        id
        name
        maximumValue
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.UpdateWorkflowRankMutation, Types.UpdateWorkflowRankMutationVariables>
