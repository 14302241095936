import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetProductDocument = gql`
  query GetProduct($productId: Int!) {
    product(id: $productId) {
      brand
      canonicalProductId
      category {
        id
        name
        parent {
          id
          name
        }
      }
      concatenatedDescription
      concatenatedSellUnit
      externalId
      id
      image
      itemDescription
      itemMeasure
      itemPackName
      itemSellPackName
      itemSellQuantity
      itemSize
      master
      owner {
        id
        name
      }
      packageQuantity
      productCode
      stockItem
    }
  }
` as unknown as DocumentNode<Types.GetProductQuery, Types.GetProductQueryVariables>
