import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './access_graphql'

export const GetUserPreferenceDocument = gql`
  query GetUserPreference($key: String!) {
    preference(key: $key) {
      key
      value
    }
  }
` as unknown as DocumentNode<Types.GetUserPreferenceQuery, Types.GetUserPreferenceQueryVariables>
