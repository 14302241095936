import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateInvoiceSubLineDocument = gql`
  mutation CreateInvoiceSubLine($input: CreateInvoiceSubLineMutationInput!) {
    createInvoiceSubLine(input: $input) {
      invoiceLine {
        id
        quantity
        unitPrice
        taxPercentage
        orderInstruction
        lineTotal
        lineTax
        subLines {
          edges {
            node {
              id
              department {
                id
                name
              }
              account {
                id
                accountName
                code
              }
              unitPrice
              tax
              taxRate
              quantity
              totalValue
              customJson
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.CreateInvoiceSubLineMutation, Types.CreateInvoiceSubLineMutationVariables>
