import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const HaltInvoiceDocument = gql`
  mutation HaltInvoice($input: HaltInvoiceMutationInput!) {
    haltInvoice(input: $input) {
      invoice {
        id
        status
      }
    }
  }
` as unknown as DocumentNode<Types.HaltInvoiceMutation, Types.HaltInvoiceMutationVariables>
