import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const ConsolidatePurchaseOrderDocument = gql`
  mutation ConsolidatePurchaseOrder($input: ConsolidatePurchaseOrderMutationInput!) {
    consolidatePurchaseOrder(input: $input) {
      purchaseOrder {
        id
        purchaseOrderNumber
      }
    }
  }
` as unknown as DocumentNode<Types.ConsolidatePurchaseOrderMutation, Types.ConsolidatePurchaseOrderMutationVariables>
