import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetReportTemplatesDocument = gql`
  query GetReportTemplates($reportId: Int!, $after: String, $before: String, $first: Int, $last: Int, $name: String) {
    report(id: $reportId) {
      id
      templates(after: $after, before: $before, first: $first, last: $last, name: $name) {
        edges {
          node {
            id
            name
            createdAt
            reportClassName
            selectedColumns
            outputFormat
            dateRange
            organisationHierarchy
            email
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetReportTemplatesQuery, Types.GetReportTemplatesQueryVariables>
