import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const ActivityFragmentDoc = gql`
  fragment Activity on Activity {
    id
    contextType
    action
    updatedAt
    user {
      id
      fullName
    }
    state
  }
` as unknown as DocumentNode<Types.ActivityFragment, unknown>
