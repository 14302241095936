import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const OperationalApproveInvoiceDocument = gql`
  mutation OperationalApproveInvoice($input: OperationalApproveInvoiceMutationInput!) {
    operationalApproveInvoice(input: $input) {
      invoice {
        id
        status
        isFinanciallyApproved
        isOperationallyApproved
        financiallyApprovable
        operationallyApprovable
        financialApprovers {
          edges {
            node {
              id
              createdAt
              user {
                id
                fullName
              }
            }
          }
        }
        operationalApprovers {
          edges {
            node {
              id
              createdAt
              user {
                id
                fullName
              }
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.OperationalApproveInvoiceMutation, Types.OperationalApproveInvoiceMutationVariables>
