import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import { InvoiceThresholdFragmentDoc } from './InvoiceThreshold.generated'
import * as Types from './purchasing_graphql'

export const CreateInvoiceThresholdDocument = gql`
  mutation CreateInvoiceThreshold($input: CreateInvoiceThresholdMutationInput!) {
    createInvoiceThreshold(input: $input) {
      invoiceThreshold {
        ...InvoiceThreshold
      }
      errors {
        ...BaseError
      }
    }
  }
  ${InvoiceThresholdFragmentDoc}
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.CreateInvoiceThresholdMutation, Types.CreateInvoiceThresholdMutationVariables>
