import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const UpdateFinanceExportVariantDocument = gql`
  mutation UpdateFinanceExportVariant($input: UpdateFinanceExportVariantInput!) {
    updateFinanceExportVariant(input: $input) {
      financeExportVariant {
        id
        name
        functionName
        outputFormat
        organisation {
          id
          name
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.UpdateFinanceExportVariantMutation,
  Types.UpdateFinanceExportVariantMutationVariables
>
