import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetAccountsPayableSystemDocument = gql`
  query GetAccountsPayableSystem($apSystemId: Int) {
    accountsPayableSystem(id: $apSystemId) {
      id
      name
      currency {
        id
        code
      }
      availableCurrencies {
        id
        code
      }
      exportFormat {
        id
        name
      }
      financeExportFormat {
        id
        name
        useAccountingPeriod
        financeExportVariants {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      financeExportVariant {
        id
        name
      }
      hasSharedSecret
      externalUrl
      identity
      oauthUrl
      sharedSecret
      lineJsonSchema
      headerJsonSchema
      accountingPeriodStartMonth
      availableExportFormats {
        id
        name
      }
      electronicDocumentDestinations {
        totalCount
        edges {
          node {
            id
            name
            externalUrl
            identity
            sharedSecret
            oauthUrl
            financeExportDefault
            pipe
            contentType
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetAccountsPayableSystemQuery, Types.GetAccountsPayableSystemQueryVariables>
