import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasing_graphql'

export const DeleteCreditNoteAttachmentDocument = gql`
  mutation DeleteCreditNoteAttachment($input: DeleteCreditNoteAttachmentMutationInput!) {
    deleteCreditNoteAttachment(input: $input) {
      success
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<
  Types.DeleteCreditNoteAttachmentMutation,
  Types.DeleteCreditNoteAttachmentMutationVariables
>
