import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasing_graphql'

export const GetAllCreditNotesDocument = gql`
  query GetAllCreditNotes(
    $after: String
    $before: String
    $filter: RansackFilterType
    $first: Int
    $last: Int
    $sort: [RansackSortType!]
  ) {
    currentPurchaser {
      id
      creditNotes(after: $after, before: $before, filter: $filter, first: $first, last: $last, sort: $sort) {
        edges {
          node {
            id
            workflowState
            creditNoteNumber
            reference
            createdAt
            currency
            purchaser {
              id
              name
            }
            organisationUnit {
              id
              name
            }
            department {
              id
              name
            }
            account {
              id
              accountName
              code
            }
            supplier {
              id
              name
            }
            invoice {
              id
              invoiceNumber
            }
            creator {
              id
              fullName
            }
            creditNoteDate
            totalValue
            totalTaxValue
            discountTotal
            createdAt
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAllCreditNotesQuery, Types.GetAllCreditNotesQueryVariables>
