import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetStockLocationTransfersDocument = gql`
  query GetStockLocationTransfers(
    $stockLocationId: Int!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchText: String
    $sort: [RansackSortType!]
    $fromDate: ISO8601Date
    $toDate: ISO8601Date
    $transferTypes: [TransferTypeEnum!]
    $destinationId: Int
    $originId: Int
  ) {
    stockLocation(id: $stockLocationId) {
      id
      transfers(
        after: $after
        before: $before
        first: $first
        last: $last
        searchText: $searchText
        sort: $sort
        fromDate: $fromDate
        toDate: $toDate
        transferTypes: $transferTypes
        destinationId: $destinationId
        originId: $originId
      ) {
        edges {
          node {
            id
            transferNumber
            reference
            type
            origin {
              id
              name
            }
            destination {
              id
              name
            }
            status
            createdAt
            updatedAt
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetStockLocationTransfersQuery, Types.GetStockLocationTransfersQueryVariables>
