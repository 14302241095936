import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const DeleteProductSupplierPreferenceDocument = gql`
  mutation DeleteProductSupplierPreference($input: DeleteProductSupplierPreferenceMutationInput!) {
    deleteProductSupplierPreference(input: $input) {
      success
    }
  }
` as unknown as DocumentNode<
  Types.DeleteProductSupplierPreferenceMutation,
  Types.DeleteProductSupplierPreferenceMutationVariables
>
