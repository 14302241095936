import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasing_graphql'

export const GetAllReceivingNotesDocument = gql`
  query GetAllReceivingNotes(
    $filter: RansackFilterType
    $last: Int
    $first: Int
    $before: String
    $after: String
    $sort: [RansackSortType!]
  ) {
    currentPurchaser {
      id
      receivingDocuments(filter: $filter, last: $last, first: $first, before: $before, after: $after, sort: $sort) {
        edges {
          node {
            id
            receivingDocumentNumber
            receivedDate
            status
            destinationId
            owner {
              id
              name
            }
            organisationUnit {
              id
              name
            }
            receiver {
              id
              fullName
            }
            destination {
              id
              name
            }
            transfer {
              id
              transferNumber
            }
            receivingTotalValue
            purchaseOrderId
            purchaseOrder {
              id
              purchaseOrderNumber
              supplier {
                id
                name
              }
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAllReceivingNotesQuery, Types.GetAllReceivingNotesQueryVariables>
