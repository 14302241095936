import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const ClosePurchaseOrderDocument = gql`
  mutation ClosePurchaseOrder($input: ClosePurchaseOrderMutationInput!) {
    closePurchaseOrder(input: $input) {
      purchaseOrder {
        id
        status
      }
    }
  }
` as unknown as DocumentNode<Types.ClosePurchaseOrderMutation, Types.ClosePurchaseOrderMutationVariables>
