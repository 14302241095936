import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetReceivingNoteInvoiceCreatorDocument = gql`
  query GetReceivingNoteInvoiceCreator($receivingDocumentId: Int!) {
    receivingDocument(id: $receivingDocumentId) {
      id
      purchaseOrder {
        id
        purchaseOrderNumber
        department {
          id
          name
        }
        account {
          id
          name
          accountName: codedName
        }
        requisition {
          id
          reference
        }
        purchaser {
          id
          name
        }
        supplier {
          id
          name
        }
        currency
      }
      receivedDate
      receivingDocumentNumber
      receivingDocumentLineItems {
        unpaginated {
          id
          unitPrice
          quantity
          product {
            id
            brand
            itemDescription
            itemMeasure
            itemPackName
            itemSellPackName
            itemSellQuantity
            itemSize
          }
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.GetReceivingNoteInvoiceCreatorQuery,
  Types.GetReceivingNoteInvoiceCreatorQueryVariables
>
