import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CreateReceivingDocumentLineItemDocument = gql`
  mutation CreateReceivingDocumentLineItem($input: CreateReceivingDocumentLineItemInput!) {
    createReceivingDocumentLineItem(input: $input) {
      receivingDocumentLineItem {
        id
      }
    }
  }
` as unknown as DocumentNode<
  Types.CreateReceivingDocumentLineItemMutation,
  Types.CreateReceivingDocumentLineItemMutationVariables
>
