import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserMutationInput!) {
    createUser(input: $input) {
      user {
        id
        email
        fullName
        defaultOrganisationId
        position
        mobilePhone
        staffCode
      }
    }
  }
` as unknown as DocumentNode<Types.CreateUserMutation, Types.CreateUserMutationVariables>
