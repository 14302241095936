import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CompleteCreditNoteDocument = gql`
  mutation CompleteCreditNote($input: CompleteCreditNoteMutationInput!) {
    completeCreditNote(input: $input) {
      creditNote {
        id
        workflowState
        financialApprovers {
          edges {
            node {
              id
              createdAt
              user {
                id
                fullName
              }
            }
          }
        }
        operationalApprovers {
          edges {
            node {
              id
              createdAt
              user {
                id
                fullName
              }
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.CompleteCreditNoteMutation, Types.CompleteCreditNoteMutationVariables>
