import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetAiScanningEmailAddressesDocument = gql`
  query GetAiScanningEmailAddresses(
    $after: String
    $before: String
    $filters: AiScanningEmailAddressesQueryFiltersInput
    $first: Int
    $last: Int
    $sorts: [AiScanningEmailAddressesQuerySortsInput!]
  ) {
    aiScanningEmailAddresses(
      after: $after
      before: $before
      filters: $filters
      first: $first
      last: $last
      sorts: $sorts
    ) {
      edges {
        node {
          id
          email
          owner {
            id
            name
          }
          orgType
          updatedAt
          createdAt
          emailType
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAiScanningEmailAddressesQuery, Types.GetAiScanningEmailAddressesQueryVariables>
