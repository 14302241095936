import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CategoriesWithSubDocument = gql`
  query CategoriesWithSub(
    $searchText: String
    $first: Int
    $after: String
    $filter: RansackFilterType
    $filterSub: RansackFilterType
    $sort: [RansackSortType!]
  ) {
    categories(searchText: $searchText, first: $first, after: $after, filter: $filter, sort: $sort) {
      edges {
        node {
          id
          name
          parentId
          categories(searchText: $searchText, filter: $filterSub, sort: $sort) {
            edges {
              node {
                id
                name
                parentId
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
` as unknown as DocumentNode<Types.CategoriesWithSubQuery, Types.CategoriesWithSubQueryVariables>
