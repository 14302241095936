import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetSupplierDirectoryDocument = gql`
  query GetSupplierDirectory(
    $sort: String
    $searchText: String
    $after: String
    $before: String
    $first: Int
    $legalEntity: Boolean
    $isVerified: Boolean
    $last: Int
  ) {
    suppliers(
      sort: $sort
      searchText: $searchText
      after: $after
      before: $before
      first: $first
      legalEntity: $legalEntity
      isVerified: $isVerified
      last: $last
    ) {
      edges {
        node {
          id
          name
          businessNumber
          email
          telephone
          creditApplicationUrl
          supplierRelationship {
            id
            isEnabled
          }
          publicCataloguedProducts {
            edges {
              node {
                id
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
` as unknown as DocumentNode<Types.GetSupplierDirectoryQuery, Types.GetSupplierDirectoryQueryVariables>
