import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetSubCategoriesDocument = gql`
  query GetSubCategories(
    $sort: [RansackSortType!]
    $searchText: String
    $filter: RansackFilterType
    $parentFilter: RansackFilterType
    $after: String
    $first: Int
  ) {
    categories(filter: $parentFilter) {
      edges {
        node {
          id
          name
          categories(sort: $sort, searchText: $searchText, filter: $filter, after: $after, first: $first) {
            edges {
              node {
                id
                name
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetSubCategoriesQuery, Types.GetSubCategoriesQueryVariables>
