import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const CreateMenuDocument = gql`
  mutation CreateMenu($input: CreateMenuInput!) {
    createMenu(input: $input) {
      menu {
        id
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.CreateMenuMutation, Types.CreateMenuMutationVariables>
