import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { FeatureFlagsFragmentDoc } from './FeatureFlags.generated'
import { OrganisationFragmentDoc } from './Organisation.generated'
import * as Types from './purchasing_graphql'

export const UpdateOrganisationDocument = gql`
  mutation UpdateOrganisation($input: UpdateOrganisationMutationInput!) {
    updateOrganisation(input: $input) {
      organisation {
        ...Organisation
        featureFlags {
          ...FeatureFlags
        }
      }
    }
  }
  ${OrganisationFragmentDoc}
  ${FeatureFlagsFragmentDoc}
` as unknown as DocumentNode<Types.UpdateOrganisationMutation, Types.UpdateOrganisationMutationVariables>
