import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const CreateTradePipeDocument = gql`
  mutation CreateTradePipe($input: CreateTradePipeInput!) {
    createTradePipe(input: $input) {
      tradePipe {
        id
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.CreateTradePipeMutation, Types.CreateTradePipeMutationVariables>
