import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const UpdatePricedCatalogueDocument = gql`
  mutation UpdatePricedCatalogue($input: UpdatePricedCatalogueMutationInput!) {
    updatePricedCatalogue(input: $input) {
      pricedCatalogue {
        id
        title
        contracted
        supplier {
          id
          name
        }
      }
    }
  }
` as unknown as DocumentNode<Types.UpdatePricedCatalogueMutation, Types.UpdatePricedCatalogueMutationVariables>
