import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetUserPermissionsDocument = gql`
  query GetUserPermissions($userId: Int!) {
    user(id: $userId) {
      id
      isAdmin
      fullName
      accessControlList
    }
  }
` as unknown as DocumentNode<Types.GetUserPermissionsQuery, Types.GetUserPermissionsQueryVariables>
