import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const PendingInvoicesDocument = gql`
  query PendingInvoices($after: String, $before: String, $first: Int, $last: Int) {
    pendingInvoices(after: $after, before: $before, last: $last, first: $first) {
      edges {
        node {
          attachmentUrl
          id
          purchaserName
          reason
          status
          supplierName
          updatedAt
          createdAt
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.PendingInvoicesQuery, Types.PendingInvoicesQueryVariables>
