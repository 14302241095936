import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetRequisitionOriginTypeDocument = gql`
  query GetRequisitionOriginType($requisitionId: Int) {
    currentPurchaser {
      id
      requisition(id: $requisitionId) {
        id
        originType
        originId
      }
    }
  }
` as unknown as DocumentNode<Types.GetRequisitionOriginTypeQuery, Types.GetRequisitionOriginTypeQueryVariables>
