import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetStocktakeStockCountsDocument = gql`
  query GetStocktakeStockCounts(
    $stockTakeId: Int!
    $after: String
    $before: String
    $filters: StockCountsQueryFiltersInput
    $first: Int
    $last: Int
    $sorts: [StockCountsQuerySortsInput!]
  ) {
    stockTake(id: $stockTakeId) {
      id
      stockCounts(after: $after, before: $before, filters: $filters, first: $first, last: $last, sorts: $sorts) {
        edges {
          node {
            id
            expectedQuantity
            openingQuantity
            quantity
            variance
            countBy
            unitValue
            productCode
            product {
              id
              image
              brand
              itemDescription
              itemMeasure
              itemSellQuantity
              itemPackName
              itemSize
              concatenatedSellUnit
              itemSellPackName
              category {
                id
              }
              barcodes {
                edges {
                  node {
                    id
                    body
                    isDefault
                    holder {
                      id
                      purchaser {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetStocktakeStockCountsQuery, Types.GetStocktakeStockCountsQueryVariables>
