import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetAccountChildrenDocument = gql`
  query GetAccountChildren($accountId: Int!, $organisationId: Int) {
    account(id: $accountId, organisationId: $organisationId) {
      id
      name
      description
      code
      parent {
        id
        name
      }
      children {
        totalCount
        unpaginated {
          id
          name
          code
          description
          enabled
          parent {
            id
            name
          }
          children {
            totalCount
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetAccountChildrenQuery, Types.GetAccountChildrenQueryVariables>
