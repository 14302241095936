import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CommentFragmentDoc = gql`
  fragment Comment on Comment {
    id
    body
    createdAt
    creator {
      id
      fullName
    }
    private
  }
` as unknown as DocumentNode<Types.CommentFragment, unknown>
