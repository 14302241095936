import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import { StockItemFragmentDoc } from './StockItem.generated'
import * as Types from './purchasePlus_graphql'

export const GetStockLocationDocument = gql`
  query GetStockLocation(
    $stockLocationId: Int!
    $searchText: String
    $sort: [RansackSortType!]
    $first: Int
    $last: Int
    $before: String
    $after: String
    $purchaserProductCode: String
    $categoryIds: [Int!]
    $externalId: String
    $barcode: String
    $stockItemId: Int
  ) {
    stockLocation(id: $stockLocationId) {
      balance
      id
      name
      type
      stockLevels(
        searchText: $searchText
        sort: $sort
        first: $first
        last: $last
        before: $before
        after: $after
        purchaserProductCode: $purchaserProductCode
        categoryIds: $categoryIds
        externalId: $externalId
        barcode: $barcode
        stockItemId: $stockItemId
      ) {
        edges {
          node {
            balanceQuantity
            balanceValue
            id
            parLevel
            reorderLevel
            unitValue
            productCode
            stockItem {
              ...StockItem
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${StockItemFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetStockLocationQuery, Types.GetStockLocationQueryVariables>
