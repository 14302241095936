import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const DeclineRequisitionDocument = gql`
  mutation DeclineRequisition($input: DeclineRequisitionMutationInput!) {
    declineRequisition(input: $input) {
      requisition {
        id
        purchaserOrganisationId
        status
        nextApprovingAgentsNames
        awaitingMyApproval
        editableForCurrentUser
      }
    }
  }
` as unknown as DocumentNode<Types.DeclineRequisitionMutation, Types.DeclineRequisitionMutationVariables>
