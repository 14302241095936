import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetStockItemSubstituteProductsDocument = gql`
  query GetStockItemSubstituteProducts(
    $stockItemId: Int!
    $sort: [RansackSortType!]
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filters: SubstituteProductsQueryFiltersInput
  ) {
    stockItem(id: $stockItemId) {
      id
      substituteProducts(after: $after, before: $before, first: $first, last: $last, sort: $sort, filters: $filters) {
        edges {
          node {
            id
            substitute {
              id
              image
              category {
                id
                name
                parent {
                  id
                  name
                }
              }
              brand
              itemDescription
              concatenatedDescription
              concatenatedSellUnit
              itemPackName
              itemMeasure
              itemSellPackName
              itemSize
              productCode
              master
              owner {
                id
                name
              }
              canonicalProductId
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<
  Types.GetStockItemSubstituteProductsQuery,
  Types.GetStockItemSubstituteProductsQueryVariables
>
