import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetAccountsPayableSystemSuppliersDocument = gql`
  query GetAccountsPayableSystemSuppliers(
    $accountsPayableSystemId: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filters: VendorsQueryFiltersInput
  ) {
    accountsPayableSystem(id: $accountsPayableSystemId) {
      id
      vendors(after: $after, before: $before, first: $first, last: $last, filters: $filters) {
        edges {
          node {
            id
            address
            code
            locationCode
            supplierName
          }
        }
        totalCount
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<
  Types.GetAccountsPayableSystemSuppliersQuery,
  Types.GetAccountsPayableSystemSuppliersQueryVariables
>
