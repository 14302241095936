import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CreateFinanceExportVariantDocument = gql`
  mutation CreateFinanceExportVariant($input: CreateFinanceExportVariantInput!) {
    createFinanceExportVariant(input: $input) {
      financeExportVariant {
        id
        name
        functionName
        outputFormat
        organisation {
          id
          name
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.CreateFinanceExportVariantMutation,
  Types.CreateFinanceExportVariantMutationVariables
>
