import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CreateAccountDocument = gql`
  mutation CreateAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      account {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.CreateAccountMutation, Types.CreateAccountMutationVariables>
