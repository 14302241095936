import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { FinanceFlagsFragmentDoc } from './FinanceFlags.generated'
import * as Types from './purchasePlus_graphql'

export const UpdateFinanceFlagSettingsDocument = gql`
  mutation UpdateFinanceFlagSettings($input: UpdateFinanceFlagSettingsInput!) {
    updateFinanceFlagSettings(input: $input) {
      financeFlagSettings {
        ...FinanceFlags
      }
    }
  }
  ${FinanceFlagsFragmentDoc}
` as unknown as DocumentNode<Types.UpdateFinanceFlagSettingsMutation, Types.UpdateFinanceFlagSettingsMutationVariables>
