import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetAccountsPayableSystemAccountsDocument = gql`
  query GetAccountsPayableSystemAccounts(
    $accountsPayableSystemId: Int
    $filters: AccountsQueryFiltersInput
    $sorts: [AccountsQuerySortsInput!]
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    accountsPayableSystem(id: $accountsPayableSystemId) {
      id
      accounts(filters: $filters, sorts: $sorts, after: $after, before: $before, first: $first, last: $last) {
        edges {
          node {
            children {
              totalCount
            }
            id
            name
            description
            code
            parent {
              id
              name
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<
  Types.GetAccountsPayableSystemAccountsQuery,
  Types.GetAccountsPayableSystemAccountsQueryVariables
>
