import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const CreateReceivingDocumentDocument = gql`
  mutation CreateReceivingDocument($input: CreateReceivingDocumentInput!) {
    createReceivingDocument(input: $input) {
      receivingDocument {
        id
        status
        purchaseOrder {
          id
          purchaseOrderNumber
        }
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.CreateReceivingDocumentMutation, Types.CreateReceivingDocumentMutationVariables>
