import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const DeleteReportTemplateDocument = gql`
  mutation DeleteReportTemplate($input: DeleteReportTemplateInput!) {
    deleteReportTemplate(input: $input) {
      success
    }
  }
` as unknown as DocumentNode<Types.DeleteReportTemplateMutation, Types.DeleteReportTemplateMutationVariables>
