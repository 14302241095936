import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetPurchaseOrderReceivedStatusDocument = gql`
  query GetPurchaseOrderReceivedStatus($purchaseOrderId: Int) {
    currentPurchaser {
      purchaseOrder(id: $purchaseOrderId) {
        id
        receivedStatus
      }
    }
  }
` as unknown as DocumentNode<
  Types.GetPurchaseOrderReceivedStatusQuery,
  Types.GetPurchaseOrderReceivedStatusQueryVariables
>
