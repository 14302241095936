import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetAccountsDocument = gql`
  query GetAccounts($departmentId: Int) {
    purchaser(id: $departmentId) {
      id
      accountsInTree {
        id
        accountName
      }
    }
  }
` as unknown as DocumentNode<Types.GetAccountsQuery, Types.GetAccountsQueryVariables>
