import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetPurchaseOrderLineItemDocument = gql`
  query GetPurchaseOrderLineItem(
    $purchaseOrderId: Int
    $purchaseOrderLineItemId: Int
    $receivingDocumentLinesfilter: RansackFilterType
  ) {
    currentPurchaser {
      id
      purchaseOrder(id: $purchaseOrderId) {
        id
        currency
        purchaseOrderLineItem(id: $purchaseOrderLineItemId) {
          id
          image
          unitPrice
          taxPercentage
          invoicedStatus
          receivedStatus
          receivedQuantity
          quantity
          product {
            id
            brand
            itemDescription
            itemSize
            itemMeasure
            itemPackName
            concatenatedSellUnit
          }
          invoiceLineItems {
            edges {
              node {
                id
                quantity
                unitPrice
                taxPercentage
                invoicePriceVariance
                invoice {
                  id
                  invoiceNumber
                  currency
                }
                originalProduct {
                  id
                  image
                  brand
                  itemDescription
                  itemSize
                  itemMeasure
                  itemPackName
                  concatenatedSellUnit
                }
              }
            }
          }
          receivingDocumentLineItems(filter: $receivingDocumentLinesfilter) {
            nodes {
              id
              receivingDocument {
                id
                receivingDocumentNumber
                receivedDate
                receiver {
                  id
                  fullName
                }
                transfer {
                  id
                  transferNumber
                }
                status
              }
              receivedQuantity
              quantity
              unitPrice
              totalValue
              receivedToInventory
              stockLocation {
                id
                name
              }
              product {
                id
                image
                brand
                itemDescription
                itemSize
                itemMeasure
                itemPackName
                concatenatedSellUnit
              }
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetPurchaseOrderLineItemQuery, Types.GetPurchaseOrderLineItemQueryVariables>
