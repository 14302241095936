import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { FeatureFlagsFragmentDoc } from './FeatureFlags.generated'
import * as Types from './purchasing_graphql'

export const GetSupplierRelationshipDocument = gql`
  query GetSupplierRelationship($id: Int) {
    currentPurchaser {
      id
      supplierRelationship(id: $id) {
        id
        supplierNumber
        supplierLocationCode
        paymentTerms
        punchoutEnabled
        punchoutUrl
        punchoutUsername
        punchoutPassword
        preferredSupplier
        isEnabled
        contactEmail
        contactFirstName
        contactLastName
        department {
          id
          name
        }
        account {
          id
          accountName
        }
        supplier {
          id
          name
          billingAddressLine1
          billingAddressLine2
          billingAddressCity
          billingAddressStateProvince
          billingAddressPostalCode
          postalAddressLine1
          postalAddressLine2
          postalAddressCity
          postalAddressStateProvince
          postalAddressPostalCode
          telephone
          email
          businessNumber
          isVerified
          deliveryChargeConfiguration {
            deliveryChargeExTax
            deliveryChargeTaxPercentage
            minimumOrderValueExTax
          }
          businessName
          businessUnit
          departmentCode
          corporateCode
          companyBrand
          postalAddressCountry
          billingAddressCountry
          billingAddressIsPostalAddress
          owner {
            id
            name
          }
          featureFlags {
            ...FeatureFlags
          }
        }
        defaultCategory {
          id
          name
          parent {
            id
            name
          }
          familyCategoryId
        }
      }
    }
  }
  ${FeatureFlagsFragmentDoc}
` as unknown as DocumentNode<Types.GetSupplierRelationshipQuery, Types.GetSupplierRelationshipQueryVariables>
