import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BarcodeFragmentDoc } from './Barcode.generated'
import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasing_graphql'

export const GetCurrentSupplierProductsDocument = gql`
  query GetCurrentSupplierProducts(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchText: String
    $hasImage: Boolean
    $catalogued: Boolean
    $matched: Boolean
    $master: Boolean
    $categoryIds: [Int!]
    $productCode: String
    $productId: Int
    $sort: [RansackSortType!]
  ) {
    currentLegalEntity {
      id
      ... on Supplier {
        availableProducts(
          after: $after
          before: $before
          first: $first
          last: $last
          searchText: $searchText
          hasImage: $hasImage
          catalogued: $catalogued
          matched: $matched
          master: $master
          categoryIds: $categoryIds
          productCode: $productCode
          productId: $productId
          sort: $sort
        ) {
          edges {
            node {
              id
              master
              productCode
              image
              brand
              itemDescription
              itemSize
              itemMeasure
              itemPackName
              externalId
              concatenatedSellUnit
              barcodes {
                edges {
                  node {
                    ...Barcode
                  }
                }
              }
              catalogues {
                edges {
                  node {
                    id
                    title
                  }
                }
              }
              category {
                id
                name
                parent {
                  id
                  name
                }
              }
              canonicalProductId
              owner {
                id
                name
              }
              brand
              itemDescription
              itemSize
              itemMeasure
              itemPackName
              concatenatedSellUnit
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${BarcodeFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetCurrentSupplierProductsQuery, Types.GetCurrentSupplierProductsQueryVariables>
