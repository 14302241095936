import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import { ProductFragmentDoc } from './Product.generated'
import { SupplierDetailByLineFragmentDoc } from './SupplierDetailByLine.generated'
import * as Types from './purchasing_graphql'

export const GetPossibleRequisitionLinesDocument = gql`
  query GetPossibleRequisitionLines(
    $requisitionId: Int
    $first: Int
    $last: Int
    $after: String
    $before: String
    $term: String
    $supplierIds: [Int!]
    $categoryIds: [Int!]
    $productId: Int
    $supplierProductCode: String
    $purchaserProductCode: String
    $bestBuy: Boolean
    $nextGenQuotes: Boolean
  ) {
    currentPurchaser {
      id
      requisition(id: $requisitionId) {
        possibleRequisitionLines(
          after: $after
          before: $before
          first: $first
          last: $last
          searchText: $term
          supplierIds: $supplierIds
          categoryIds: $categoryIds
          productId: $productId
          supplierProductCode: $supplierProductCode
          purchaserProductCode: $purchaserProductCode
          bestBuy: $bestBuy
          nextGenQuotes: $nextGenQuotes
        ) {
          edges {
            node {
              id
              uuid
              cataloguedProductId
              quantity
              image
              unitPrice
              taxPercentage
              supplierProductCode
              purchaserProductCode
              longDescription
              preferredSupplier
              product {
                ...Product
              }
              supplier {
                ...SupplierDetailByLine
                supplierRelationship {
                  id
                  preferredSupplier
                }
              }
              livePremium(nextGenQuotes: $nextGenQuotes)
              availableQuotes(nextGenQuotes: $nextGenQuotes) {
                unitPrice
                taxPercentage
                supplierName
                supplierId
                cataloguedProductId
                preferredSupplier
                contracted
              }
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
        id
        requisitionNumber
        reference
        totalValue
        totalValueExcludingTax
        totalTaxValue
        lines {
          id
          uuid
          product {
            id
          }
          cataloguedProductId
        }
      }
    }
  }
  ${ProductFragmentDoc}
  ${SupplierDetailByLineFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetPossibleRequisitionLinesQuery, Types.GetPossibleRequisitionLinesQueryVariables>
