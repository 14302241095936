import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { DeliveryFragmentDoc } from './Delivery.generated'
import * as Types from './purchasing_graphql'

export const GetPurchaseOrderSendEventsDocument = gql`
  query GetPurchaseOrderSendEvents($purchaseOrderId: Int, $sort: [RansackSortType!]) {
    currentPurchaser {
      id
      purchaseOrder(id: $purchaseOrderId) {
        id
        purchaseOrderNumber
        statusEnum
        deliveries(sort: $sort) {
          edges {
            node {
              ...Delivery
            }
          }
        }
      }
    }
  }
  ${DeliveryFragmentDoc}
` as unknown as DocumentNode<Types.GetPurchaseOrderSendEventsQuery, Types.GetPurchaseOrderSendEventsQueryVariables>
