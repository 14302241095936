import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { StockItemFragmentDoc } from './StockItem.generated'
import * as Types from './purchasePlus_graphql'

export const RecipeFragmentDoc = gql`
  fragment Recipe on Recipe {
    id
    name
    image
    yieldQuantity
    stockItem {
      ...StockItem
    }
    liveCostPrice
    stockedCostPrice
    costPricePerUnit
    retailPricePerUnit
    presentation
    preparationMethod
    code
    measure
    size
    retailPricePerUnit
    createdAt
    costPercentage
    owner {
      id
      name
    }
  }
  ${StockItemFragmentDoc}
` as unknown as DocumentNode<Types.RecipeFragment, unknown>
