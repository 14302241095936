import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasing_graphql'

export const UpdateCreditNoteDocument = gql`
  mutation UpdateCreditNote($input: UpdateCreditNoteMutationInput!) {
    updateCreditNote(input: $input) {
      creditNote {
        id
        creditNoteNumber
        creditNoteDate
        currency
        customJson
        department {
          id
          name
        }
        account {
          id
          accountName
        }
        reference
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.UpdateCreditNoteMutation, Types.UpdateCreditNoteMutationVariables>
