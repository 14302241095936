import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasing_graphql'

export const GetAllBuyListsDocument = gql`
  query GetAllBuyLists(
    $filter: RansackFilterType
    $last: Int
    $first: Int
    $before: String
    $after: String
    $sort: [RansackSortType!]
    $cataloguedProductsFilter: RansackFilterType
  ) {
    currentPurchaser {
      id
      catalogues(filter: $filter, last: $last, first: $first, before: $before, after: $after, sort: $sort) {
        edges {
          node {
            id
            owner {
              id
              name
            }
            title
            cataloguedProducts(filter: $cataloguedProductsFilter) {
              edges {
                node {
                  id
                  product {
                    id
                  }
                }
              }
            }
            updatedAt
            chargingDefinitions {
              edges {
                node {
                  id
                  department {
                    id
                    name
                  }
                  account {
                    id
                    accountName
                  }
                }
              }
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAllBuyListsQuery, Types.GetAllBuyListsQueryVariables>
