import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasing_graphql'

export const CreateCreditNoteDocument = gql`
  mutation CreateCreditNote($input: CreateCreditNoteMutationInput!) {
    createCreditNote(input: $input) {
      creditNote {
        id
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.CreateCreditNoteMutation, Types.CreateCreditNoteMutationVariables>
