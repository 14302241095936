import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetProductImportsDocument = gql`
  query GetProductImports($after: String, $before: String, $first: Int, $last: Int, $search: String) {
    productImports(after: $after, before: $before, first: $first, last: $last, search: $search) {
      edges {
        node {
          id
          importFileLink
          user {
            fullName
            id
          }
          organisation {
            id
            name
          }
          createdAt
          importFileName
          importInformation
          state
          importErrorFileLink
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetProductImportsQuery, Types.GetProductImportsQueryVariables>
