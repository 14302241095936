import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const FeatureFlagsFragmentDoc = gql`
  fragment FeatureFlags on FeatureFlags {
    apHubType
    enableLiveChat
    enableAiScanning
    inventory
    paperlessInvoicingOnly
    promoBanners
    quickCreate
    bestBuyDefault
    showAllSuppliersPrType
    showBudgetsTab
    showBuyListPrType
    showBuyListsTab
    showCatalogsTab
    showCurrencySymbols
    showCustomPrType
    showDocumentPrType
    showExternalPrType
    showInvoicesTab
    showPointOfSaleTab
    showProductsTab
    showPurchaseOrdersTab
    showRecipesTab
    showReportsTab
    showRequisitionsTab
    showSingleSupplierPrType
    showReOrderPrType
    showSuppliersTab
    supplierShowCatalogsTab
    supplierShowInventoryTab
    supplierShowInvoicesTab
    supplierShowProductsTab
    supplierShowPurchaseOrdersTab
    supplierShowPurchasersTab
    supplierShowRecipesTab
    supplierShowReportsTab
    showInvoiceAnalysis2Way
    showInvoiceAnalysis3Way
    showReOrderPrType
    useNextGenQuotes
    useFastNextGenQuotes
  }
` as unknown as DocumentNode<Types.FeatureFlagsFragment, unknown>
