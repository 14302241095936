import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasing_graphql'

export const GetAllBarcodesDocument = gql`
  query GetAllBarcodes(
    $after: String
    $before: String
    $filter: RansackFilterType
    $first: Int
    $last: Int
    $sort: [RansackSortType!]
  ) {
    barcodes(after: $after, before: $before, filter: $filter, first: $first, last: $last, sort: $sort) {
      edges {
        node {
          id
          createdAt
          body
          isDefault
          organisation {
            id
            name
          }
          product {
            id
            brand
            itemDescription
            concatenatedDescription
            concatenatedSellUnit
            itemPackName
            itemMeasure
            itemSellPackName
            itemSize
            productCode
            barcodes {
              edges {
                node {
                  id
                  body
                  isDefault
                }
              }
            }
          }
          updatedAt
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAllBarcodesQuery, Types.GetAllBarcodesQueryVariables>
