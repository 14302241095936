import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasing_graphql'

export const GetRequisitionsAwaitingApprovalDocument = gql`
  query GetRequisitionsAwaitingApproval(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: RansackFilterType
  ) {
    currentPurchaser {
      id
      requisitionsAwaitingMyApproval(first: $first, last: $last, before: $before, after: $after, filter: $filter) {
        edges {
          node {
            id
            status
            requisitionNumber
            reference
            originType
            originName
            currency
            creator {
              id
              fullName
            }
            createdAt
            totalValueExcludingTax
            totalTaxValue
            totalValue
            nextApprovingAgentsNames
            nextApprovingUsers {
              id
              fullName
            }
            expectedDeliveryDate
            sendToSupplier
            awaitingMyApproval
            approvalWorkflow {
              id
              title
            }
            purchaser {
              id
              name
            }
            organisationUnit {
              id
              name
            }
            department {
              id
              name
            }
            account {
              id
              accountName
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<
  Types.GetRequisitionsAwaitingApprovalQuery,
  Types.GetRequisitionsAwaitingApprovalQueryVariables
>
