import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './inventory_graphql'

export const BaseErrorFragmentDoc = gql`
  fragment BaseError on BaseError {
    extensions {
      code
      status
    }
    message
  }
` as unknown as DocumentNode<Types.BaseErrorFragment, unknown>
