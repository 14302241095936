import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const UpdatePricedCataloguedProductDocument = gql`
  mutation UpdatePricedCataloguedProduct($input: UpdatePricedCataloguedProductMutationInput!) {
    updatePricedCataloguedProduct(input: $input) {
      pricedCataloguedProduct {
        id
        sellUnitPrice
        sellUnitTaxPercentage
        supplierProductCode
      }
    }
  }
` as unknown as DocumentNode<
  Types.UpdatePricedCataloguedProductMutation,
  Types.UpdatePricedCataloguedProductMutationVariables
>
