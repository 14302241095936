import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetWorkflowsDocument = gql`
  query GetWorkflows(
    $after: String
    $before: String
    $filters: WorkflowsQueryFiltersInput
    $first: Int
    $last: Int
    $sorts: [WorkflowsQuerySortsInput!]
  ) {
    workflows(after: $after, before: $before, filters: $filters, first: $first, last: $last, sorts: $sorts) {
      edges {
        node {
          id
          name
          type
          owner {
            id
            name
          }
          description
          createdAt
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetWorkflowsQuery, Types.GetWorkflowsQueryVariables>
