import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const ClearExpenseLocationsDocument = gql`
  mutation ClearExpenseLocations($input: ClearExpenseLocationsInput!) {
    clearExpenseLocations(input: $input) {
      accountingTransfers {
        id
        transferNumber
        status
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.ClearExpenseLocationsMutation, Types.ClearExpenseLocationsMutationVariables>
