import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const UpdateCataloguedProductDocument = gql`
  mutation UpdateCataloguedProduct($input: UpdateCataloguedProductMutationInput!) {
    updateCataloguedProduct(input: $input) {
      cataloguedProduct {
        id
        department {
          id
          name
        }
        account {
          id
          accountName
        }
      }
    }
  }
` as unknown as DocumentNode<Types.UpdateCataloguedProductMutation, Types.UpdateCataloguedProductMutationVariables>
