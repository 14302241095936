import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetFinanceExportVariantsDocument = gql`
  query GetFinanceExportVariants(
    $financeExportFormatId: Int!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    financeExportFormat(id: $financeExportFormatId) {
      id
      financeExportVariants(after: $after, before: $before, first: $first, last: $last) {
        edges {
          node {
            id
            name
            organisation {
              id
              name
            }
            outputFormat
            functionName
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetFinanceExportVariantsQuery, Types.GetFinanceExportVariantsQueryVariables>
