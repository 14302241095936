import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetAllMenusDocument = gql`
  query GetAllMenus(
    $after: String
    $before: String
    $filters: MenusQueryFiltersInput
    $first: Int
    $last: Int
    $sorts: [MenusQuerySortsInput!]
  ) {
    menus(after: $after, before: $before, filters: $filters, first: $first, last: $last, sorts: $sorts) {
      edges {
        node {
          id
          name
          createdAt
          recipes {
            totalCount
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAllMenusQuery, Types.GetAllMenusQueryVariables>
