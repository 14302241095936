import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateCataloguedProductsDocument = gql`
  mutation CreateCataloguedProducts($input: CreateCataloguedProductsMutationInput!) {
    createCataloguedProducts(input: $input) {
      cataloguedProducts {
        id
        product {
          id
          inBuyListCount
          catalogues {
            edges {
              node {
                id
                title
              }
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.CreateCataloguedProductsMutation, Types.CreateCataloguedProductsMutationVariables>
