import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetCategoriesDocument = gql`
  query GetCategories(
    $sort: [RansackSortType!]
    $searchText: String
    $filter: RansackFilterType
    $after: String
    $first: Int
  ) {
    categories(sort: $sort, searchText: $searchText, filter: $filter, after: $after, first: $first) {
      edges {
        node {
          id
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
` as unknown as DocumentNode<Types.GetCategoriesQuery, Types.GetCategoriesQueryVariables>
