import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const ExportInvoiceDocument = gql`
  mutation ExportInvoice($input: ExportInvoiceInput!) {
    exportInvoice(input: $input) {
      invoice {
        id
        status
      }
    }
  }
` as unknown as DocumentNode<Types.ExportInvoiceMutation, Types.ExportInvoiceMutationVariables>
