import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasing_graphql'

export const ClearProductSupplierPreferencesDocument = gql`
  mutation ClearProductSupplierPreferences($productId: Int!, $purchaserId: Int!) {
    clearProductSupplierPreferences(input: { productId: $productId, purchaserId: $purchaserId }) {
      success
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<
  Types.ClearProductSupplierPreferencesMutation,
  Types.ClearProductSupplierPreferencesMutationVariables
>
