import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CancelReceivingDocumentDocument = gql`
  mutation CancelReceivingDocument($input: CancelReceivingDocumentInput!) {
    cancelReceivingDocument(input: $input) {
      receivingDocument {
        id
        purchaseOrder {
          id
          purchaseOrderNumber
        }
        status
      }
    }
  }
` as unknown as DocumentNode<Types.CancelReceivingDocumentMutation, Types.CancelReceivingDocumentMutationVariables>
