import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const UpdateInvoiceSubLineDocument = gql`
  mutation UpdateInvoiceSubLine($input: UpdateInvoiceSubLineMutationInput!) {
    updateInvoiceSubLine(input: $input) {
      invoiceLine {
        id
        taxPercentage
        quantity
        unitPrice
        lineTotal
        subLines {
          edges {
            node {
              id
              department {
                id
                name
              }
              account {
                id
                accountName
                code
              }
              unitPrice
              tax
              taxRate
              quantity
              totalValue
              lineType
              department {
                id
                name
              }
              account {
                id
                accountName
              }
              description
              reference
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.UpdateInvoiceSubLineMutation, Types.UpdateInvoiceSubLineMutationVariables>
