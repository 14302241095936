import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetInvoiceAiScanningJobDocument = gql`
  query GetInvoiceAiScanningJob($invoiceAiScanningJobId: Int!) {
    invoiceAiScanningJob(id: $invoiceAiScanningJobId) {
      id
      documentUrl
      jsonResponse
      state
    }
  }
` as unknown as DocumentNode<Types.GetInvoiceAiScanningJobQuery, Types.GetInvoiceAiScanningJobQueryVariables>
