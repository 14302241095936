import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const GetCataloguesDocument = gql`
  query GetCatalogues($filter: RansackFilterType, $after: String, $first: Int, $sort: [RansackSortType!]) {
    currentPurchaser {
      id
      catalogues(filter: $filter, after: $after, first: $first, sort: $sort) {
        edges {
          node {
            id
            title
            catalogueChargingDefinitions {
              edges {
                node {
                  id
                  department {
                    id
                    name
                  }
                  account {
                    id
                    accountName
                  }
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetCataloguesQuery, Types.GetCataloguesQueryVariables>
