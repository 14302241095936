import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const SendPurchaseOrderDocument = gql`
  mutation SendPurchaseOrder(
    $updatePOInput: UpdatePurchaseOrderMutationInput!
    $sendPOInput: SendPurchaseOrderMutationInput!
  ) {
    updatePurchaseOrder(input: $updatePOInput) {
      purchaseOrder {
        id
        sentDate
        statusEnum
      }
    }
    sendPurchaseOrder(input: $sendPOInput) {
      purchaseOrder {
        id
        sentDate
        statusEnum
      }
    }
  }
` as unknown as DocumentNode<Types.SendPurchaseOrderMutation, Types.SendPurchaseOrderMutationVariables>
