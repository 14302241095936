import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './access_graphql'

export const GetUserDetailsDocument = gql`
  query GetUserDetails($userId: Int) {
    user(id: $userId) {
      id
      fullName
      email
      telephone
      staffCode
      createdAt
      currentSignInAt
      position
      isDeleted
      mobilePhone
      confirmedAt
      enableMultiFactorAuthentication
      defaultOrganisation {
        id
        name
      }
    }
  }
` as unknown as DocumentNode<Types.GetUserDetailsQuery, Types.GetUserDetailsQueryVariables>
