import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CancelInvoiceDocument = gql`
  mutation CancelInvoice($input: CancelInvoiceMutationInput!) {
    cancelInvoice(input: $input) {
      invoice {
        id
        status
      }
    }
  }
` as unknown as DocumentNode<Types.CancelInvoiceMutation, Types.CancelInvoiceMutationVariables>
