import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CreateTransferItemDocument = gql`
  mutation CreateTransferItem($input: CreateTransferItemInput!) {
    createTransferItem(input: $input) {
      transferItem {
        id
        stockItem {
          id
        }
      }
    }
  }
` as unknown as DocumentNode<Types.CreateTransferItemMutation, Types.CreateTransferItemMutationVariables>
