import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasing_graphql'

export const GetAllCatalogsDocument = gql`
  query GetAllCatalogs(
    $filter: RansackFilterType
    $last: Int
    $first: Int
    $before: String
    $after: String
    $sort: [RansackSortType!]
  ) {
    currentPurchaser {
      id
      availableCatalogues(filter: $filter, last: $last, first: $first, before: $before, after: $after, sort: $sort) {
        edges {
          node {
            id
            title
            updatedAt
            contracted
            supplier {
              id
              name
            }
            owner {
              id
              ... on Supplier {
                name
                isAPurchaser
                isASupplier
              }
              ... on Purchaser {
                name
                isAPurchaser
                isASupplier
              }
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAllCatalogsQuery, Types.GetAllCatalogsQueryVariables>
