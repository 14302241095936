import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const ReceiveReceivingDocumentDocument = gql`
  mutation ReceiveReceivingDocument($input: ReceiveReceivingDocumentInput!) {
    receiveReceivingDocument(input: $input) {
      receivingDocument {
        id
        status
        purchaseOrder {
          id
          purchaseOrderNumber
          lines {
            nodes {
              id
              receivedStatus
            }
          }
        }
        receivingDocumentLineItems {
          edges {
            node {
              id
              quantity
              receivedQuantity
              purchaseOrderLineItem {
                id
                quantity
                receivedQuantity
              }
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.ReceiveReceivingDocumentMutation, Types.ReceiveReceivingDocumentMutationVariables>
