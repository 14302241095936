import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CreateReportTemplateDocument = gql`
  mutation CreateReportTemplate($input: CreateReportTemplateInput!) {
    createReportTemplate(input: $input) {
      reportTemplate {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.CreateReportTemplateMutation, Types.CreateReportTemplateMutationVariables>
