import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const DeleteFinanceExportVariantDocument = gql`
  mutation DeleteFinanceExportVariant($input: DeleteFinanceExportVariantInput!) {
    deleteFinanceExportVariant(input: $input) {
      success
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<
  Types.DeleteFinanceExportVariantMutation,
  Types.DeleteFinanceExportVariantMutationVariables
>
