import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const InvoiceThresholdFragmentDoc = gql`
  fragment InvoiceThreshold on InvoiceThreshold {
    id
    unitPriceValue
    unitPricePercentage
    unitPriceUseHigher
    lineQuantityValue
    lineQuantityPercentage
    lineQuantityUseHigher
    invoiceTotalValue
    invoiceTotalPercentage
    invoiceTotalUseHigher
  }
` as unknown as DocumentNode<Types.InvoiceThresholdFragment, unknown>
