import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetTradeRelationshipDocument = gql`
  query GetTradeRelationship($tradeRelationshipId: Int!) {
    tradeRelationship(id: $tradeRelationshipId) {
      id
      status
      supplierNumber
      supplierLocationCode
      supplierNumber
      supplierLocationCode
      paymentTerms
      punchoutEnabled
      punchoutUrl
      punchoutUsername
      punchoutPassword
      preferredSupplier
      isEnabled
      isEnabledBySupplier
      supplierContact {
        id
        firstName
        lastName
        workEmail
      }
      defaultCategory {
        id
        name
        parent {
          id
          name
        }
      }
      department {
        id
        name
      }
      account {
        id
        codedName
      }
      purchaser {
        id
        name
        accountsPayableSystem {
          id
          name
        }
      }
      supplier {
        id
        name
      }
    }
  }
` as unknown as DocumentNode<Types.GetTradeRelationshipQuery, Types.GetTradeRelationshipQueryVariables>
