import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CreateOrUpdatePartyProductDocument = gql`
  mutation CreateOrUpdatePartyProduct($input: CreateOrUpdatePartyProductInput!) {
    createOrUpdatePartyProduct(input: $input) {
      partyProduct {
        id
        code
      }
    }
  }
` as unknown as DocumentNode<
  Types.CreateOrUpdatePartyProductMutation,
  Types.CreateOrUpdatePartyProductMutationVariables
>
