import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const MarkProductAsStockItemDocument = gql`
  mutation MarkProductAsStockItem($input: MarkProductAsStockItemInput!) {
    markProductAsStockItem(input: $input) {
      stockItem {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.MarkProductAsStockItemMutation, Types.MarkProductAsStockItemMutationVariables>
