import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasing_graphql'

export const CreateRequisitionLineDocument = gql`
  mutation CreateRequisitionLine($input: CreateRequisitionLineMutationInput!) {
    createRequisitionLine(input: $input) {
      errors {
        ...BaseError
      }
      requisition {
        id
        totalValue
        totalValueExcludingTax
        totalTaxValue
        advanced
        lines {
          id
          uuid
          product {
            id
          }
          cataloguedProductId
          quantity
        }
        requisitionLinesBySuppliers {
          supplier {
            id
            name
          }
          total
          totalTax
          lines {
            id
            uuid
            quantity
            unitPrice
            product {
              id
            }
            cataloguedProductId
            orderInstruction
            department {
              id
              name
            }
            account {
              id
              accountName
              code
            }
            tax
            total
          }
        }
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.CreateRequisitionLineMutation, Types.CreateRequisitionLineMutationVariables>
