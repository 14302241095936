import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { StockItemFragmentDoc } from './StockItem.generated'
import * as Types from './purchasePlus_graphql'

export const UpdateTransferItemDocument = gql`
  mutation UpdateTransferItem($input: UpdateTransferItemInput!) {
    updateTransferItem(input: $input) {
      transferItem {
        unitValue
        totalCost
        quantity
        value
        id
        origin {
          id
          unitValue
          balanceQuantity
        }
        stockItem {
          ...StockItem
        }
      }
    }
  }
  ${StockItemFragmentDoc}
` as unknown as DocumentNode<Types.UpdateTransferItemMutation, Types.UpdateTransferItemMutationVariables>
