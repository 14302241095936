import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetStockItemStockLevelsDocument = gql`
  query GetStockItemStockLevels($stockItemId: Int!, $after: String, $before: String, $first: Int, $last: Int) {
    stockItem(id: $stockItemId) {
      id
      stockLevels(after: $after, before: $before, first: $first, last: $last) {
        edges {
          node {
            id
            balanceQuantity
            balanceValue
            unitValue
            countBy
            location {
              id
              name
            }
            stockItem {
              id
              itemPackName
              itemMeasure
              countBy
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetStockItemStockLevelsQuery, Types.GetStockItemStockLevelsQueryVariables>
