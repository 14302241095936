import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { CommentFragmentDoc } from './Comment.generated'
import { SupplierDetailByLineFragmentDoc } from './SupplierDetailByLine.generated'
import * as Types from './purchasing_graphql'

export const GetCreditNoteDocument = gql`
  query GetCreditNote($creditNoteId: Int, $sortComments: [RansackSortType!]) {
    currentPurchaser {
      id
      creditNote(id: $creditNoteId) {
        id
        creditNoteNumber
        workflowState
        totalValue
        totalTaxValue
        deliveryChargeExTax
        deliveryChargeTax
        adjustmentExTax
        adjustmentTax
        commentsCount
        taxCodeInfo
        reference
        creditNoteDate
        createdAt
        customJson
        currency
        department {
          id
          name
        }
        account {
          id
          accountName
        }
        purchaser {
          id
          name
        }
        organisationUnit {
          id
          name
        }
        invoice {
          id
          invoiceNumber
        }
        supplier {
          ...SupplierDetailByLine
        }
        creditNoteLines {
          edges {
            node {
              id
            }
          }
        }
        financialApprovers {
          edges {
            node {
              id
              createdAt
              user {
                id
                fullName
              }
            }
          }
        }
        operationalApprovers {
          edges {
            node {
              id
              createdAt
              user {
                id
                fullName
              }
            }
          }
        }
        comments(sort: $sortComments) {
          edges {
            node {
              ...Comment
            }
          }
        }
      }
    }
  }
  ${SupplierDetailByLineFragmentDoc}
  ${CommentFragmentDoc}
` as unknown as DocumentNode<Types.GetCreditNoteQuery, Types.GetCreditNoteQueryVariables>
