import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const UnlinkInvoiceDocument = gql`
  mutation UnlinkInvoice($input: UnlinkInvoiceMutationInput!) {
    unlinkInvoice(input: $input) {
      invoice {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.UnlinkInvoiceMutation, Types.UnlinkInvoiceMutationVariables>
