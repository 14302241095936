import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetProcurementProductsDocument = gql`
  query GetProcurementProducts(
    $after: String
    $before: String
    $filters: ProcurementProductsQueryFiltersInput
    $first: Int
    $last: Int
    $sorts: [ProcurementProductsQuerySortsInput!]
  ) {
    procurementProducts(after: $after, before: $before, filters: $filters, first: $first, last: $last, sorts: $sorts) {
      edges {
        node {
          id
          code
          organisation {
            id
            name
          }
          product {
            id
            brand
            itemDescription
            concatenatedDescription
            concatenatedSellUnit
            itemPackName
            itemMeasure
            itemSellPackName
            itemSize
            productCode
            owner {
              id
              name
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetProcurementProductsQuery, Types.GetProcurementProductsQueryVariables>
