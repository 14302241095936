import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { DeliveryFragmentDoc } from './Delivery.generated'
import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasing_graphql'

export const GetAllPurchaseOrdersDocument = gql`
  query GetAllPurchaseOrders(
    $filter: RansackFilterType
    $last: Int
    $first: Int
    $before: String
    $after: String
    $sort: [RansackSortType!]
    $sortDeliveries: [RansackSortType!]
    $skipInvoices: Boolean!
  ) {
    currentPurchaser {
      id
      purchaseOrders(filter: $filter, last: $last, first: $first, before: $before, after: $after, sort: $sort) {
        edges {
          node {
            id
            consolidated
            statusEnum
            purchaseOrderNumber
            totalValue
            totalValueExcludingTax
            totalTaxValue
            sentDate
            expectedDeliveryDate
            sendToSupplier
            currency
            updatedAt
            purchaser {
              id
              name
            }
            organisationUnit {
              id
              name
            }
            receivingDocuments {
              edges {
                node {
                  id
                  receivingDocumentNumber
                  status
                }
              }
            }
            updator {
              id
              fullName
            }
            supplier {
              id
              name
            }
            department {
              id
              name
            }
            account {
              id
              accountName
              code
            }
            requisition {
              id
              reference
              requisitionNumber
            }
            invoices @skip(if: $skipInvoices) {
              edges {
                node {
                  id
                  invoiceNumber
                  totalValue
                }
              }
            }
            deliveries(sort: $sortDeliveries) {
              edges {
                node {
                  ...Delivery
                }
              }
            }
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${DeliveryFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAllPurchaseOrdersQuery, Types.GetAllPurchaseOrdersQueryVariables>
