import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasing_graphql'

export const UpdateContractCatalogueDocument = gql`
  mutation UpdateContractCatalogue($input: UpdateContractCatalogueMutationInput!) {
    updateContractCatalogue(input: $input) {
      contractCatalogue {
        id
        title
        contracted
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.UpdateContractCatalogueMutation, Types.UpdateContractCatalogueMutationVariables>
