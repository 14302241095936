import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const ProductFragmentDoc = gql`
  fragment Product on Product {
    id
    image
    category {
      id
      name
      parent {
        id
        name
      }
    }
    brand
    itemDescription
    concatenatedDescription
    concatenatedSellUnit
    itemPackName
    itemMeasure
    itemSellPackName
    itemSize
    productCode
    master
    owner {
      id
      name
    }
    canonicalProductId
  }
` as unknown as DocumentNode<Types.ProductFragment, unknown>
