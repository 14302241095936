import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BarcodeFragmentDoc } from './Barcode.generated'
import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasing_graphql'

export const GetCurrentPurchaserProductsDocument = gql`
  query GetCurrentPurchaserProducts(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchText: String
    $master: Boolean
    $categoryIds: [Int!]
    $sortPrices: [RansackSortType!]
    $productId: Int
    $purchaserProductCode: String
    $hasQuotes: Boolean
    $hasRules: Boolean
    $excludeBarcodes: Boolean!
    $sort: [RansackSortType!]
    $barcode: String
    $ownerId: Int
    $nextGenQuotes: Boolean
  ) {
    currentPurchaser {
      id
      name
      isASupplier
      isAPurchaser
      availableProducts(
        after: $after
        before: $before
        first: $first
        last: $last
        searchText: $searchText
        master: $master
        categoryIds: $categoryIds
        productId: $productId
        purchaserProductCode: $purchaserProductCode
        hasQuotes: $hasQuotes
        hasRules: $hasRules
        sort: $sort
        barcode: $barcode
        ownerId: $ownerId
        nextGenQuotes: $nextGenQuotes
      ) {
        edges {
          node {
            id
            master
            productCode
            image
            brand
            itemDescription
            itemSize
            itemMeasure
            itemPackName
            externalId
            concatenatedSellUnit
            stockItem
            barcodes @skip(if: $excludeBarcodes) {
              edges {
                node {
                  ...Barcode
                }
              }
            }
            prices(sort: $sortPrices) {
              edges {
                node {
                  supplierName
                  unitPrice
                  taxPercentage
                  preferredSupplier
                  contracted
                }
              }
            }
            owner {
              id
              name
            }
            catalogues {
              edges {
                node {
                  id
                  title
                }
              }
            }
            category {
              id
              name
              parent {
                id
                name
              }
            }
            canonicalProductId
            owner {
              id
              name
            }
            brand
            itemDescription
            itemSize
            itemMeasure
            itemPackName
            itemSellPackName
            itemSellQuantity
            concatenatedSellUnit
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${BarcodeFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetCurrentPurchaserProductsQuery, Types.GetCurrentPurchaserProductsQueryVariables>
