import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BarcodeFragmentDoc } from './Barcode.generated'
import { PageInfoFragmentDoc } from './PageInfo.generated'
import { ProductFragmentDoc } from './Product.generated'
import * as Types from './purchasing_graphql'

export const GetBuyListDocument = gql`
  query GetBuyList(
    $sortPrices: [RansackSortType!]
    $catalogueId: Int
    $filter: RansackFilterType
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    currentPurchaser {
      id
      catalogue(id: $catalogueId) {
        id
        title
        catalogueSupplierChoices {
          edges {
            node {
              id
              supplier {
                id
                name
              }
            }
          }
        }
        department {
          id
          name
        }
        account {
          id
          accountName
        }
        owner {
          id
          name
        }
        createdAt
        customSorted
        user {
          id
          fullName
        }
        cataloguedProducts(filter: $filter, after: $after, before: $before, first: $first, last: $last) {
          edges {
            node {
              id
              department {
                id
                name
              }
              account {
                id
                accountName
              }
              product {
                ...Product
                master
                barcodes {
                  edges {
                    node {
                      ...Barcode
                    }
                  }
                }
                prices(sort: $sortPrices) {
                  edges {
                    node {
                      supplierName
                      unitPrice
                      taxPercentage
                      preferredSupplier
                      contracted
                    }
                  }
                }
              }
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${ProductFragmentDoc}
  ${BarcodeFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetBuyListQuery, Types.GetBuyListQueryVariables>
