import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetRecipeMenusDocument = gql`
  query GetRecipeMenus($recipeId: Int!, $after: String, $before: String, $first: Int, $last: Int) {
    recipe(id: $recipeId) {
      id
      menus(after: $after, before: $before, first: $first, last: $last) {
        edges {
          node {
            id
            name
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetRecipeMenusQuery, Types.GetRecipeMenusQueryVariables>
