import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const BarcodeFragmentDoc = gql`
  fragment Barcode on Barcode {
    id
    body
    isDefault
    organisation {
      id
      name
    }
  }
` as unknown as DocumentNode<Types.BarcodeFragment, unknown>
