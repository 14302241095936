import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const SubmitRequisitionDocument = gql`
  mutation SubmitRequisition($id: Int!, $input: UpdateRequisitionMutationInput!) {
    updateRequisition(input: $input) {
      requisition {
        id
        reference
        sendToSupplier
        expectedDeliveryDate
        nextApprovingAgentsNames
        approvalWorkflow {
          id
          title
        }
        deliveryAddress {
          id
          locationName
          line1
          line2
          city
          stateProvince
          country
        }
        department {
          id
          name
        }
        account {
          id
          accountName
        }
      }
    }
    submitRequisition(input: { id: $id }) {
      requisition {
        id
        status
        requisitionNumber
        nextApprovingAgentsNames
        awaitingMyApproval
        purchaseOrders {
          edges {
            node {
              id
              sentDate
              purchaseOrderNumber
              supplier {
                id
                name
              }
              totalValue
              statusEnum
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.SubmitRequisitionMutation, Types.SubmitRequisitionMutationVariables>
