import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const UpdateLegacyReceivingDocumentLineItemDocument = gql`
  mutation UpdateLegacyReceivingDocumentLineItem($input: UpdateReceivingDocumentLineItemMutationInput!) {
    updateReceivingDocumentLineItem(input: $input) {
      receivingDocumentLineItem {
        id
        quantity
        receivedQuantity
        unitPrice
        overrideUnitValue
        expDate
        temperature
        vehicleTemperature
        purchaseOrderLineItem {
          id
          quantity
        }
        comment
        totalValue
        receivingDocument {
          id
          receivingTotalValue
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.UpdateLegacyReceivingDocumentLineItemMutation,
  Types.UpdateLegacyReceivingDocumentLineItemMutationVariables
>
