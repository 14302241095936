import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './access_graphql'

export const GetOrganisationsDocument = gql`
  query GetOrganisations($filter: RansackFilterType, $sort: [RansackSortType!], $first: Int, $after: String) {
    organisations(filter: $filter, sort: $sort, first: $first, after: $after) {
      edges {
        node {
          id
          name
          parent {
            id
            name
            parent {
              id
              name
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
` as unknown as DocumentNode<Types.GetOrganisationsQuery, Types.GetOrganisationsQueryVariables>
