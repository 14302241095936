import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const UpdateProductDocument = gql`
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      product {
        id
        brand
        canonicalProductId
        category {
          id
          name
          parent {
            id
            name
          }
        }
        image
        itemDescription
        itemMeasure
        itemPackName
        itemSellPackName
        itemSellQuantity
        itemSize
        master
        owner {
          id
          name
        }
        packageQuantity
        concatenatedDescription
        concatenatedSellUnit
        defaultSalePrice
        externalId
      }
    }
  }
` as unknown as DocumentNode<Types.UpdateProductMutation, Types.UpdateProductMutationVariables>
