import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const UpdateAiScanningEmailAddressDocument = gql`
  mutation UpdateAiScanningEmailAddress($input: UpdateAiScanningEmailAddressInput!) {
    updateAiScanningEmailAddress(input: $input) {
      aiScanningEmailAddress {
        id
        email
        orgType
        owner {
          id
          name
        }
        updatedAt
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<
  Types.UpdateAiScanningEmailAddressMutation,
  Types.UpdateAiScanningEmailAddressMutationVariables
>
