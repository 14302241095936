import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const ElectronicDocumentDestinationsDocument = gql`
  query ElectronicDocumentDestinations {
    electronicDocumentDestinations {
      totalCount
      edges {
        node {
          id
          name
          externalUrl
          identity
          sharedSecret
          oauthUrl
          financeExportDefault
          pipe
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.ElectronicDocumentDestinationsQuery,
  Types.ElectronicDocumentDestinationsQueryVariables
>
