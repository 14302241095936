import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetWorkflowDocument = gql`
  query GetWorkflow($workflowId: Int) {
    workflow(id: $workflowId) {
      id
      name
      type
      createdAt
      description
      owner {
        id
        name
      }
      creator {
        id
        fullName
      }
    }
  }
` as unknown as DocumentNode<Types.GetWorkflowQuery, Types.GetWorkflowQueryVariables>
