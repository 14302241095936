import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetUnpaginatedTradeRelationshipPipesDocument = gql`
  query GetUnpaginatedTradeRelationshipPipes($tradeRelationshipId: Int!) {
    tradeRelationship(id: $tradeRelationshipId) {
      id
      pipes {
        unpaginated {
          type
          id
          isDefault
          externalUrl
        }
      }
    }
  }
` as unknown as DocumentNode<
  Types.GetUnpaginatedTradeRelationshipPipesQuery,
  Types.GetUnpaginatedTradeRelationshipPipesQueryVariables
>
