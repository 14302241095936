import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetUnpaginatedStockCountsDocument = gql`
  query GetUnpaginatedStockCounts($stockTakeId: Int!) {
    stockTake(id: $stockTakeId) {
      id
      stockCounts {
        unpaginated {
          id
          variance
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetUnpaginatedStockCountsQuery, Types.GetUnpaginatedStockCountsQueryVariables>
