import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetOrganisationAccountsDocument = gql`
  query GetOrganisationAccounts(
    $after: String
    $before: String
    $filters: AccountsQueryFiltersInput
    $first: Int
    $last: Int
    $sorts: [AccountsQuerySortsInput!]
    $organisationId: Int!
  ) {
    organisation(id: $organisationId) {
      id
      ... on Purchaser {
        allAccountsDisabled
        accounts(after: $after, before: $before, filters: $filters, first: $first, last: $last, sorts: $sorts) {
          pageInfo {
            ...PageInfo
          }
          edges {
            node {
              id
              name
              code
              description
              parent {
                id
                name
              }
              children {
                totalCount
              }
            }
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetOrganisationAccountsQuery, Types.GetOrganisationAccountsQueryVariables>
