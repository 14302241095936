import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CancelCreditNoteDocument = gql`
  mutation CancelCreditNote($input: CancelCreditNoteMutationInput!) {
    cancelCreditNote(input: $input) {
      creditNote {
        id
        workflowState
      }
    }
  }
` as unknown as DocumentNode<Types.CancelCreditNoteMutation, Types.CancelCreditNoteMutationVariables>
