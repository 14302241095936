import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetStocktakeDocument = gql`
  query GetStocktake($stockTakeId: Int!) {
    stockTake(id: $stockTakeId) {
      id
      periodEndingOn
      createdAt
      closedAt
      blind
      status
      countStatus
      creator {
        id
        fullName
      }
      updator {
        id
        fullName
      }
      stockLocation {
        id
        name
      }
    }
  }
` as unknown as DocumentNode<Types.GetStocktakeQuery, Types.GetStocktakeQueryVariables>
