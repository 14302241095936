import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const UpdateFinanceExportFormatDocument = gql`
  mutation UpdateFinanceExportFormat($input: UpdateFinanceExportFormatInput!) {
    updateFinanceExportFormat(input: $input) {
      financeExportFormat {
        id
        name
        outputFormat
        useAccountingPeriod
        exportClassName
        gqlQuery
      }
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.UpdateFinanceExportFormatMutation, Types.UpdateFinanceExportFormatMutationVariables>
