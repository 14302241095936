import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import { InvoiceThresholdFragmentDoc } from './InvoiceThreshold.generated'
import * as Types from './purchasing_graphql'

export const UpdateInvoiceThresholdDocument = gql`
  mutation UpdateInvoiceThreshold($input: UpdateInvoiceThresholdMutationInput!) {
    updateInvoiceThreshold(input: $input) {
      invoiceThreshold {
        ...InvoiceThreshold
      }
      errors {
        ...BaseError
      }
    }
  }
  ${InvoiceThresholdFragmentDoc}
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.UpdateInvoiceThresholdMutation, Types.UpdateInvoiceThresholdMutationVariables>
