import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const CreateLegacyReceivingDocumentLineItemDocument = gql`
  mutation CreateLegacyReceivingDocumentLineItem($input: CreateReceivingDocumentLineItemMutationInput!) {
    createReceivingDocumentLineItem(input: $input) {
      receivingDocumentLineItem {
        id
      }
    }
  }
` as unknown as DocumentNode<
  Types.CreateLegacyReceivingDocumentLineItemMutation,
  Types.CreateLegacyReceivingDocumentLineItemMutationVariables
>
