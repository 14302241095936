import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasing_graphql'

export const DeleteCataloguedProductDocument = gql`
  mutation DeleteCataloguedProduct($input: DeleteCataloguedProductMutationInput!) {
    deleteCataloguedProduct(input: $input) {
      success
      errors {
        ...BaseError
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.DeleteCataloguedProductMutation, Types.DeleteCataloguedProductMutationVariables>
