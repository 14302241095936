import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetProductBarcodesDocument = gql`
  query GetProductBarcodes($productId: Int!) {
    product(id: $productId) {
      barcodes {
        edges {
          node {
            id
            body
            isDefault
            organisation {
              id
              name
            }
          }
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetProductBarcodesQuery, Types.GetProductBarcodesQueryVariables>
