import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const AddTradePipeToTradeRelationshipDocument = gql`
  mutation AddTradePipeToTradeRelationship($input: AddTradePipeToTradeRelationshipInput!) {
    addTradePipeToTradeRelationship(input: $input) {
      tradePiping {
        id
      }
    }
  }
` as unknown as DocumentNode<
  Types.AddTradePipeToTradeRelationshipMutation,
  Types.AddTradePipeToTradeRelationshipMutationVariables
>
