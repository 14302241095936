import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const FlaggingMessageFragmentDoc = gql`
  fragment FlaggingMessage on FlaggingMessage {
    type
    values
    financeFlagSetting
  }
` as unknown as DocumentNode<Types.FlaggingMessageFragment, unknown>
