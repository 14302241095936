import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CreatePunchoutRequisitionDocument = gql`
  mutation CreatePunchoutRequisition($input: CreatePunchoutRequisitionInput!) {
    createPunchoutRequisition(input: $input) {
      punchoutRequisition {
        location
      }
    }
  }
` as unknown as DocumentNode<Types.CreatePunchoutRequisitionMutation, Types.CreatePunchoutRequisitionMutationVariables>
