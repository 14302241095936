import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const CreateAiScanningJobDocument = gql`
  mutation CreateAiScanningJob($input: CreateAiScanningJobInput!) {
    createAiScanningJob(input: $input) {
      aiScanningJob {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.CreateAiScanningJobMutation, Types.CreateAiScanningJobMutationVariables>
