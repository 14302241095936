import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { RecipeFragmentDoc } from './Recipe.generated'
import * as Types from './purchasePlus_graphql'

export const UpdateRecipeDocument = gql`
  mutation UpdateRecipe($input: UpdateRecipeInput!) {
    updateRecipe(input: $input) {
      recipe {
        ...Recipe
      }
    }
  }
  ${RecipeFragmentDoc}
` as unknown as DocumentNode<Types.UpdateRecipeMutation, Types.UpdateRecipeMutationVariables>
