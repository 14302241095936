import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import { StockItemFragmentDoc } from './StockItem.generated'
import * as Types from './purchasePlus_graphql'

export const GetAllStockItemsDocument = gql`
  query GetAllStockItems(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filters: StockItemsQueryFiltersInput
    $sorts: [StockItemsQuerySortsInput!]
  ) {
    stockItems(after: $after, before: $before, first: $first, last: $last, filters: $filters, sorts: $sorts) {
      edges {
        node {
          ...StockItem
          stockLevels {
            unpaginated {
              id
              balanceQuantity
              balanceValue
              unitValue
              countBy
              location {
                id
                name
              }
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${StockItemFragmentDoc}
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAllStockItemsQuery, Types.GetAllStockItemsQueryVariables>
