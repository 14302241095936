import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetProductsDocument = gql`
  query GetProducts(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchText: String
    $filter: RansackFilterType
    $sort: [RansackSortType!]
  ) {
    products(
      after: $after
      before: $before
      first: $first
      last: $last
      searchText: $searchText
      filter: $filter
      sort: $sort
    ) {
      edges {
        node {
          id
          canonicalProductId
          itemDescription
          brand
          image
          itemSize
          itemMeasure
          itemSellPackName
          itemPackName
          itemSellQuantity
          master
          category {
            id
            name
            parent {
              id
              name
            }
          }
          owner {
            id
            name
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetProductsQuery, Types.GetProductsQueryVariables>
