import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { DeliveryFragmentDoc } from './Delivery.generated'
import * as Types from './purchasing_graphql'

export const GetRequisitionPurchaseOrdersDocument = gql`
  query GetRequisitionPurchaseOrders($requisitionId: Int, $sortDeliveries: [RansackSortType!]) {
    currentPurchaser {
      id
      requisition(id: $requisitionId) {
        id
        requisitionNumber
        purchaseOrders {
          edges {
            node {
              id
              purchaseOrderNumber
              currency
              requisition {
                id
                reference
                requisitionNumber
              }
              supplier {
                id
                name
              }
              department {
                id
                name
              }
              account {
                id
                code
              }
              deliveries(sort: $sortDeliveries) {
                edges {
                  node {
                    ...Delivery
                  }
                }
              }
              statusEnum
              sentDate
              expectedDeliveryDate
              totalValue
            }
          }
        }
      }
    }
  }
  ${DeliveryFragmentDoc}
` as unknown as DocumentNode<Types.GetRequisitionPurchaseOrdersQuery, Types.GetRequisitionPurchaseOrdersQueryVariables>
