import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { ActivityFragmentDoc } from './Activity.generated'
import * as Types from './purchasing_graphql'

export const GetInvoiceActivitiesDocument = gql`
  query GetInvoiceActivities($invoiceId: Int) {
    currentPurchaser {
      id
      invoice(id: $invoiceId) {
        id
        invoiceNumber
        activities {
          edges {
            node {
              ...Activity
            }
          }
        }
      }
    }
  }
  ${ActivityFragmentDoc}
` as unknown as DocumentNode<Types.GetInvoiceActivitiesQuery, Types.GetInvoiceActivitiesQueryVariables>
