import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './access_graphql'

export const UpdateUserPreferenceDocument = gql`
  mutation UpdateUserPreference($input: UpdatePreferenceMutationInput!) {
    updatePreference(input: $input) {
      preference {
        key
        value
      }
    }
  }
` as unknown as DocumentNode<Types.UpdateUserPreferenceMutation, Types.UpdateUserPreferenceMutationVariables>
