import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const MarkFinanceExportDocumentsExportedDocument = gql`
  mutation MarkFinanceExportDocumentsExported($input: MarkFinanceExportDocumentsExportedInput!) {
    markFinanceExportDocumentsExported(input: $input) {
      financeExportExecution {
        id
      }
    }
  }
` as unknown as DocumentNode<
  Types.MarkFinanceExportDocumentsExportedMutation,
  Types.MarkFinanceExportDocumentsExportedMutationVariables
>
