import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetCurrentOrganisationUsersDocument = gql`
  query GetCurrentOrganisationUsers(
    $after: String
    $before: String
    $filters: UsersQueryFiltersInput
    $first: Int
    $last: Int
    $sorts: [UsersQuerySortsInput!]
  ) {
    currentOrganisationUnit {
      id
      users(after: $after, before: $before, filters: $filters, first: $first, last: $last, sorts: $sorts) {
        edges {
          node {
            id
            fullName
            email
            defaultOrganisation {
              id
              name
            }
            telephone
            currentSignInAt
            isDeleted
            position
            confirmedAt
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetCurrentOrganisationUsersQuery, Types.GetCurrentOrganisationUsersQueryVariables>
