import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const AddRecipeToMenuDocument = gql`
  mutation AddRecipeToMenu($input: AddRecipeToMenuInput!) {
    addRecipeToMenu(input: $input) {
      menuRecipe {
        id
      }
    }
  }
` as unknown as DocumentNode<Types.AddRecipeToMenuMutation, Types.AddRecipeToMenuMutationVariables>
