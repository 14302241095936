import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { BaseErrorFragmentDoc } from './BaseError.generated'
import * as Types from './purchasePlus_graphql'

export const ExportInvoicesDocument = gql`
  mutation ExportInvoices($input: ExportInvoicesInput!) {
    exportInvoices(input: $input) {
      errors {
        ...BaseError
      }
      processed {
        id
        invoiceNumber
        status
      }
      failed {
        errors {
          ...BaseError
        }
        invoice {
          id
          invoiceNumber
          status
        }
      }
    }
  }
  ${BaseErrorFragmentDoc}
` as unknown as DocumentNode<Types.ExportInvoicesMutation, Types.ExportInvoicesMutationVariables>
