import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasing_graphql'

export const ReopenCreditNoteDocument = gql`
  mutation ReopenCreditNote($input: ReopenCreditNoteMutationInput!) {
    reopenCreditNote(input: $input) {
      creditNote {
        id
        workflowState
      }
    }
  }
` as unknown as DocumentNode<Types.ReopenCreditNoteMutation, Types.ReopenCreditNoteMutationVariables>
