import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetReceivingNoteDocument = gql`
  query GetReceivingNote($receivingDocumentId: Int!) {
    receivingDocument(id: $receivingDocumentId) {
      id
      status
      receivingDocumentNumber
      receivedDate
      physicalDate
      purchaseOrder {
        id
        purchaseOrderNumber
        supplier {
          id
          name
        }
      }
      destination {
        id
        name
      }
      owner {
        id
        name
      }
      organisationUnit {
        id
        name
      }
      receiver {
        id
        fullName
      }
      transfers {
        nodes {
          id
          transferNumber
        }
      }
    }
  }
` as unknown as DocumentNode<Types.GetReceivingNoteQuery, Types.GetReceivingNoteQueryVariables>
